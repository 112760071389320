@import '@scss/core-busuu/core-partials/_colors';

.courses-table-preloader {
  @keyframes shimmer {
    0% {
      fill: $color-quaternary-light;
    }
    50% {
      fill: darken($color-quaternary-light, 3);
    }
    100% {
      fill: $color-quaternary-light;
    }
  }
  width: 100%;

  rect[fill='#F2F7FD'] {
    animation: shimmer 0.75s infinite;
  }
}
