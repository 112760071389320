@import '@scss/core-busuu/core-partials/_colors';

.conversation-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &--wide {
    width: calc(100% + 50px);
  }

  &__image {
    width: 220px;
    height: 124px;
    margin: 0 10px 10px 10px;
    border-radius: 8px;
  }
}

.conversation-hint {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    font-size: 16px;
    color: $color-brand-base;

    &:hover {
      cursor: pointer;
    }
    .conversation-arrow {
      margin-left: 10px;
      transform: rotate(180deg);

      &--opened {
        transform: rotate(0deg);
      }
    }
  }

  &__text {
    margin-top: 24px;
    font-size: 18px;
  }
}
.conversation-question {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-size: 18px;
  font-weight: bold;
}

.conversation-buttons {
  margin: auto;
  margin-top: 40px;
  max-width: 400px;
  display: flex;
  justify-content: space-between;

  &--desktop {
    width: 50%;
  }

  &--tablet {
    width: 75%;
  }

  &--mobile {
    width: 80%;
  }
}
.conversation-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__caption {
    font-size: 18px;
    margin-top: 32px;
  }
}
