@import '@scss/utils/_mixins';

.search-results {
  flex-grow: 1;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  &__results-list {
    overflow: hidden;
    @include resetList();
    padding-bottom: 50px;
  }

  &__results-list-item {
    @include resetList();

    border-bottom: 0.1rem solid $color-quaternary-base;
    margin-bottom: $spacingBasic;
  }

  &__results-info-wrapper {
    max-height: 68vh;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: $spacingBig $spacingHuge;
    padding-right: 1.5rem;
    padding-bottom: 0px;
    width: 100%;

    &--for-reusing {
      max-height: 75vh;
    }
  }

  &__results-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-bottom: $spacingBig;
  }

  &__results,
  &__info {
    width: 100%;

    .audio-upload {
      margin-right: $spacingMedium;
    }
  }

  &__results,
  &__info {
    width: 100%;

    &--empty,
    &--no-results {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__info {
    margin-bottom: $spacingBig;
  }

  &__results {
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 2rem;
    height: 100%;
    @include customStyledScrollbar();
  }

  &__blank-space-filler,
  &__no-results {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  &__blank-space-filler-image,
  &__no-results-image {
    background-repeat: no-repeat;
    background-size: contain;
    height: 11.1rem;
    margin-bottom: $spacingLarge;
    width: 11.9rem;
  }

  &__blank-space-filler-image {
    background-image: url(~@static/svg/searchIllustration.svg);
  }

  &__no-results-image {
    background-image: url(~@static/svg/searchNotFound.svg);
  }

  &__blank-space-filler-title,
  &__no-results-title {
    @extend .Web_Section_subheader;
    max-width: 45.8rem;
    text-align: center;
    width: 100%;
  }

  &__blank-space-filler-instructions,
  &__no-results-instructions {
    @extend .Web_Body_text;
    max-width: 29.8rem;
    text-align: center;
    width: 100%;
  }

  &__results-totals {
    @extend .LG_18px_800;
    margin: 0;
  }
}
