@import '@scss/core-busuu/core-partials/_colors';

.flashcard {
  &-star-icon {
    position: absolute;
    top: 12px;
    right: 24px;
  }

  &-background {
    width: 100%;
    border-radius: 8px;
  }

  &__video {
    & > div:first-child {
      height: fit-content !important;
    }
  }

  &-player {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  &-image {
    position: relative;
    margin-bottom: 16px;

    &--desktop {
      .flashcard-background {
        height: 395px;
      }

      .flashcard-player {
        width: 700px;
      }
    }

    &--tablet {
      .flashcard-background {
        height: 280px;
      }

      .flashcard-player {
        width: 500px;
      }
    }

    &--mobile {
      .flashcard-background {
        height: 184px;
      }

      .flashcard-player {
        width: 328px;
      }
    }
  }

  &-text {
    font-size: 30px;
    margin-bottom: 8px;

    &--interface {
      font-size: 20px;
      color: $color-quaternary-darker;
    }
  }

  &-examples {
    padding-top: 16px;
    border-top: 1px solid $color-quaternary-base;

    .flashcard-example {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &__text {
        font-size: 14px;
        color: $color-brand-dark;
        margin-right: 8px;
      }
    }

    &__example {
      font-size: 18px;
      color: $color-dark;
    }
  }
}
