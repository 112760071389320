@import '@scss/core-busuu/core-partials/_colors';

.content-toggler {
  $blockName: &;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;

    .dropdown-menu {
      padding: 0;
    }

    &--for-alt-values {
      .dropdown-item {
        padding: 2px 10px;
        color: $color-dark !important;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__toggle {
    @include noSelect();
    @include togglerLookAndFeel();
    align-items: center;
    justify-content: flex-start;

    img {
      margin-left: 0.5rem;
    }
  }

  &--no-margin {
    .content-toggler__toggle {
      margin-bottom: 0 !important;
    }
  }

  &--show-options-on-over {
    .dropdown {
      @include genericTransition('opacity');
    }

    &:hover {
      .dropdown {
        @include genericTransition('opacity');
        opacity: 1;
      }
    }
  }

  &__toggled-text-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.8rem;
  }

  &__toggled-text {
    @extend .label-field;
    margin: 0;
    margin-right: 0.8rem;
  }

  &__toggled-text-dots {
    cursor: pointer;
  }

  .dropdown {
    min-height: initial;
    min-width: initial;
  }

  .dropdown-menu {
    background: $color-light;
    border: 1px solid $color-quaternary-base;
    border-radius: $genericBorderRadius;
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
  }

  .dropdown .dropdown-item {
    color: $color-dark !important;

    &#{$blockName}__toggled-text-trash-text {
      color: $color-secondary-base !important;
    }
  }

  &__icon {
    margin-right: 1rem;
    position: relative;
    top: -0.2rem;
  }
}
