.string-search-result {
  &__content-course-language,
  &__content-course-english {
    margin-right: 1.8rem;

    span {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      max-height: 7.5rem;
      overflow: hidden;
      position: relative;
      top: $spacingTiny;
    }
  }

  &__content-course-language,
  &__content-course-english,
  &__content-translation-stats {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    max-width: 31%;
    width: 100%;
  }

  &__content-translation-stats {
    width: 20rem;
  }

  &__content-translation-stats-translations,
  &__content-translation-stats-sounds {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 2rem;
  }

  &__content-translation-stats-translations {
    margin-right: 20px;
    background: url(~@static/svg/search/translation.svg) no-repeat left center;
  }

  &__content-translation-stats-sounds {
    background: url(~@static/svg/search/audio.svg) no-repeat left center;
  }

  &__content {
    @extend .MD_14px_500;

    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__content-course-language,
  &__content-course-english {
    margin-right: 1.8rem;
  }

  &__content-course-language,
  &__content-course-english,
  &__content-translation-stats {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    max-width: 31%;
    width: 100%;
  }

  &__content-translation-stats-translations,
  &__content-translation-stats-sounds {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    top: $spacingTiny;
  }

  &__content-translation-stats-and-image {
    align-items: center;
    display: flex;
    flex-grow: 1;
    position: relative;
  }
}
