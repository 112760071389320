@import '@scss/core-busuu/core-partials/_colors';

.tip-icon {
  width: 40px;
  margin: 52px 0 20px 0;
}

.tip-text {
  color: $color-dark;
  font-size: 18px;
  margin-bottom: 24px;
}

.tip-examples {
  .tip-example {
    background: $color-light;
    border: 1px solid $color-quaternary-base;
    min-height: 59px;
    display: table;
    margin-top: -2px;
    width: 100%;

    &--only-one {
      border-radius: 8px !important;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &__instance {
      padding: 16px;
      font-size: 18px;
      width: 100%;
      border-right: 1px solid $color-quaternary-base;
      margin-top: -1px;
      display: table-cell;
      vertical-align: middle;

      &:last-child {
        border: none;
      }

      &--2 {
        width: 50%;
        text-align: center;

        &:last-child {
          border: none;
        }
      }

      &--3 {
        width: 33%;
        text-align: center;

        &:last-child {
          width: 34%;
          border: none;
        }
      }
    }
  }
}
