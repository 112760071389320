@import '@scss/core-busuu/core-partials/_colors';

.editor {
  &__toolbar {
    background-color: $color-quaternary-light !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0 0.1rem 0 $color-quaternary-base;
    border-bottom: solid 0.1rem $color-quaternary-base;
    height: 4.5rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;

    &--hidden {
      display: none !important;
    }

    .rdw-option-wrapper {
      background-color: transparent !important;
    }
  }

  &__wrapper {
    flex-wrap: wrap;
    position: static;

    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: 1.6rem;

      > div {
        background: $color-light;
        padding: 0.65rem 1rem;
      }
    }

    .public-DraftEditorPlaceholder-root {
      @extend %placeholder;
      padding-top: 2rem;
    }

    .DraftEditor-editorContainer > div {
      // background: $color-light;

      &::placeholder {
        background: transparent;
      }
    }
  }
}
