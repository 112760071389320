@import '@scss/core-busuu/core-partials/_colors';

.remove-modal {
  .modal-content {
    border-radius: 16px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 300px;
    align-items: center;
    padding: 40px;
  }
}
