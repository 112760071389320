@import '@scss/utils/_mixins';
@import '@scss/core-busuu/core-partials/_colors';

.dropdown {
  color: $color-light;
  min-height: 3.9rem;
  min-width: 12rem;

  .dropdown-toggle,
  .dropdown-item {
    @extend %genericFormComponent;
    min-height: inherit;
  }

  .dropdown-toggle {
    padding-left: 1.6rem;
    padding-right: 2.6rem;
    position: relative;
    text-align: left;
    width: 100%;

    &::after {
      position: absolute;
      right: 1rem;
      top: calc(50% - 0.25rem);
    }
  }

  .dropdown-menu.show {
    max-height: 50rem;
    min-width: 100%;
    overflow: auto;
    z-index: map-get($zIndices, 'dropdown-menu.show');

    @include customStyledScrollbar();
  }

  .dropdown-menu.show {
    bottom: auto !important;
    inset: 0px auto auto 0px !important;
  }

  .dropdown-item {
    background-color: rgba($color-light, 1);
    border: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    transition: all #{$animationTime};

    &:hover {
      background-color: rgba($color-quaternary-base, 0.5);
      border-radius: 0;
      transition: all #{$animationTime};
    }
  }

  &--big {
    @extend .ml-4;

    .dropdown-toggle {
      @extend .btn-primary;
      align-items: center;

      background: $color-brand-base;
      border-radius: $genericBorderRadius !important;
      color: $color-light;
      display: flex;
      font-size: 1.6rem !important;
      font-weight: bold;
      justify-content: center;
      margin: 0 !important;
      padding: 1.1rem 2rem !important;
      vertical-align: middle;
      width: 11.6rem;

      &::after {
        display: none;
      }

      .plus {
        left: -0.2rem;
        position: relative;
        top: -0.2rem;
      }
    }

    .dropdown-menu.show {
      border: 1px solid $color-quaternary-base;
      border-radius: 8px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      min-width: 24rem;
      right: 0px !important;
    }

    .dropdown-item {
      color: $color-dark;
    }
  }

  &__country-flag {
    @extend .flag__country-flag;

    height: $spacingMedium;
    position: relative;
    top: -0.2rem;
    width: $spacingMedium;
  }
}
