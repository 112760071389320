.phoneme-table-english {
  overflow: hidden;

  path {
    @include genericTransition('opacity');
  }

  &--limit-reached {
    path {
      opacity: 0.5;
    }
  }
}
