.nav-tabs {
  border: 0;
  border-bottom: 0.1rem solid $color-quaternary-base;
  border-color: $color-quaternary-base;
  margin-bottom: 2rem;
  padding-bottom: 0rem;

  .nav-link {
    background: none;
    border: none;
    border-color: $color-quaternary-base;
    color: $color-quaternary-darker;
    font-size: 1.6rem;
    margin-right: 2.4rem;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      @extend %bold;

      border: none;
      border-bottom: 0.3rem solid $color-quaternary-darker;
      border-color: initial;
      color: $color-brand-base;
      padding-bottom: 1rem;
    }
  }
}
