.btn {
  @extend %medium;

  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 150%;
  padding: 1.2rem 2.4rem;
}

.btn-primary {
  background-color: $color-brand-base;
}

.btn--xl {
  border-radius: $genericBorderRadius;
  font-size: $fontsize-xl;
  margin: 0;
  padding: 1rem 2rem;
}

.btn--l {
  border-radius: $genericBorderRadius;
  font-size: $fontsize-xl;
  padding: 0.75rem 2rem;
}

.btn--sm {
  border-radius: $genericBorderRadius;
}
