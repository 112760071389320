/* Buttons - Define all the website buttons
------------------------------------------- */

@mixin btn-brand($bg-color) {
  border: 0.125rem solid $bg-color;
  color: $color-brand-base;
  background-color: $bg-color;
  &:not([disabled]):hover,
  &:not([disabled]):active,
  &:not([disabled]):focus {
    color: $bg-color;
    background-color: transparent;
  }
}

@mixin btn-default($bg-color, $hover-color) {
  border: 0.125rem solid $bg-color;
  color: $color-light;
  background-color: $bg-color;
  &:not([disabled]):hover,
  &:not([disabled]):active,
  &:not([disabled]):focus {
    color: $color-light;
    background-color: $hover-color;
    border-color: $hover-color;
  }
}

@mixin btn-default--dark($bg-color, $hover-color) {
  border: 0.125rem solid $bg-color;
  color: $color-dark;
  background-color: $bg-color;
  &:not([disabled]):hover,
  &:not([disabled]):active,
  &:not([disabled]):focus {
    color: $color-dark;
    background-color: $hover-color;
    border-color: $hover-color;
  }
}

@mixin btn-outline($bg-color) {
  border: 0.125rem solid $bg-color;
  color: $bg-color;
  background-color: transparent;
  &:not([disabled]):hover,
  &:not([disabled]):active,
  &:not([disabled]):focus {
    color: lighten($bg-color, 10%);
    border-color: lighten($bg-color, 10%);
  }
}

/**
 * Default
 */
.btn {
  display: inline-block;
  border-radius: 2.815rem;
  margin: 0 0.3125rem 0.3125rem;
  padding: 0.75rem 2.5rem;

  text-align: center;
  font-size: $fontsize-m;
  line-height: normal;

  cursor: pointer;
  text-decoration: none;
  word-wrap: break-word;
  @include prefix(transition, all 0.2s, webkit);
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

/**
 * Colors
 */
.btn--brand {
  @include btn-brand($color-light);
}
.btn--default {
  @include btn-default($color-brand-base, $color-brand-light);
}
.btn--primary {
  @include btn-default($color-primary-base, $color-primary-light);
}
.btn--secondary {
  @include btn-default($color-secondary-base, $color-secondary-light);
}
.btn--tertiary {
  @include btn-default($color-tertiary-base, $color-tertiary-light);
}
.btn--premium-plus {
  @include btn-default($color-premium-plus-base, $color-premium-plus-light);
}
.btn--premium {
  @include btn-default($color-premium-base, lighten($color-premium-base, 10%));
}
.btn--primary-lighter {
  @include btn-default--dark($color-primary-lighter, lighten($color-primary-lighter, 10%));
}
.btn--outline {
  @include btn-outline($color-quaternary-dark);
}
.btn--outline-light {
  @include btn-outline($color-light);
}
.btn--outline-default {
  @include btn-outline($color-brand-base);
}
.btn--outline-tertiary {
  @include btn-outline($color-tertiary-base);
}
.btn--outline-premium-plus {
  @include btn-outline($color-premium-plus-base);
}

/**
 * REFERRAL_SMOKE_TEST
 */
.btn--facebook {
  @include btn-default($facebook-brand, lighten($facebook-brand, 10%));
}
.btn--twitter {
  @include btn-default($twitter-brand, lighten($twitter-brand, 10%));
}
.btn--whatsapp {
  @include btn-default($whatsapp-brand, lighten($whatsapp-brand, 10%));
}

/**
 * Modifier - Square
 */
.btn--square {
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/**
 * Sizes
 */
.btn--s {
  padding: 0.375rem 1.75rem;
  font-size: $fontsize-s;
}

/**
 * Spinner
 */
.btn.main-spinner-a {
  position: relative;
}
.btn.main-spinner-a:after {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  margin-top: -0.75rem;
}
.btn.main-spinner--s-a:after {
  right: 0.375rem;
  margin-top: -0.5rem;
}
/**
 * Icon
 */
.btn--icon {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--icon .icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
.btn--s.btn--icon .icon {
  height: 1rem;
  width: 1rem;
}
