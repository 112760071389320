.resource-bundle-search-result {
  &__content-course-language {
    margin-right: 1.8rem;
    display: flex;
    max-width: 55%;
    width: 100%;

    span {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      max-height: 7.5rem;
      overflow: hidden;
      position: relative;
      top: $spacingTiny;
    }
  }

  &__content-translation-stats-translations,
  &__content-translation-stats-sounds {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 2rem;
  }

  &__content-translation-stats-translations {
    background: url(~@static/svg/search/translation.svg) no-repeat left center;
  }

  &__content-translation-stats-sounds {
    background: url(~@static/svg/search/audio.svg) no-repeat left center;
  }

  &__content-translation-stats-and-image {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    margin-right: 30px;
  }

  &__content {
    align-items: center;

    &-image {
      margin-right: $spacingMedium;
      border-radius: 8px;
      width: 55px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      &--empty-container {
        background: $color-quaternary-light;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
