.search {
  $filtersAreaWidth: 38.7rem;

  &__term-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .search-box .form-control {
      @extend .LG_18px_400;
      font-size: 1.8rem;
    }
  }

  &__term-search-area,
  &__search-domain {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $spacingBasic;
    max-width: 68.8rem;
    padding: 0 2rem;
    width: 100%;
  }

  &__term-search-area-term {
    margin-right: $spacingBasic;
    width: 100%;
  }

  &__search-domain {
    @extend .MD_14px_400;

    align-items: center;
    color: $color-quaternary-darker;
    display: flex;
    justify-content: flex-start;

    &-text,
    &-language {
      margin-right: $spacingBasic;
    }
  }

  &__search-filters {
    @include resetList();

    align-items: flex-start;
    background: $color-quaternary-light;
    border-bottom-right-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 2.4rem;
    width: $filtersAreaWidth;

    ul {
      @include resetList();
      @extend .MD_16px_400;
    }

    label {
      @include noSelect();
      cursor: pointer;
    }

    span {
      position: relative;
      top: -1px;
    }
  }

  &__search-filter {
    margin-bottom: 4.8rem;

    .dropdown {
      margin: 0;
    }
  }

  &__search-filter-name {
    @extend .SM_14px_800_CAPS;
    margin-bottom: $spacingMedium;
  }

  &__search-type {
    &.nav-tabs {
      margin: 0;

      .nav-item {
        @extend .MD_16px_500;

        margin-right: $spacingBig;
        padding: 0;

        &:first-child {
          margin-bottom: $spacingBasic;
          margin-left: 4.4rem;
        }
      }
    }
  }

  &__dropdown-with-icon-value {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    img,
    svg {
      margin-right: $spacingBasic;
    }
  }
}

.search-modal {
  @include modalClosable();

  padding: 0 2rem;

  .modal-dialog {
    max-width: 132.8rem;
    width: 100%;
  }

  .modal-content {
    min-height: 90vh;
    border-radius: 0.8rem;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    padding: 0;
  }

  .nav-tabs {
    margin: 0;
    padding: 0;
    padding-left: 4.4rem;
  }

  .tab-content {
    flex-grow: 1;
    display: flex;

    .tab-pane {
      width: 100%;

      .search-results {
        height: 100%;
      }
    }
  }

  &__searching {
    @extend %bold;
  }
}
