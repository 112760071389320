@import '@scss/core-busuu/core-partials/_colors';

.flag {
  @include noSelect();
  $blockName: &;

  align-items: center;
  display: flex;
  font-size: 1.4rem;
  position: relative;

  &--no-country-name {
    #{$blockName}__text {
      min-width: auto;
    }

    #{$blockName} img {
      min-width: 2.4rem;
    }
  }

  &__country {
    @extend .mr-2;
    display: inline-block;
    width: 2rem;
  }

  &__country-flag,
  &__country-flag--multi {
    border-radius: 50%;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 1);
    height: 1.6rem;
    margin-right: 0.5rem;
  }

  &__country-flag--multi {
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    img {
      box-shadow: 0 0 0.1rem rgba(0, 0, 0, 1);
    }
  }

  &__text {
    color: $color-quaternary-darker;
    min-width: 2rem;
    position: relative;
    top: 0.2rem;

    &:hover {
      color: $color-brand-light;
      cursor: pointer;
      text-decoration: none;
      transition: #{$animationTime};
    }

    &--after {
      margin-right: 0.5rem;
    }

    &--before {
      margin-left: 0rem;
    }

    &--after-multi {
      margin-right: 0.5rem;
    }

    &--before-multi {
      margin-left: 0.5rem;
    }

    &--hover {
      &:hover {
        color: $color-brand-light;
        cursor: pointer;
        text-decoration: none;
        transition: #{$animationTime};
      }
    }
  }

  &__country-flag--multi li:nth-child(n + 2) {
    margin-left: -0.75rem;
  }

  &__more-countries-circle {
    @extend %bold;
    align-items: center;
    background: $color-light;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem;
    display: flex;
    font-size: 1rem;
    height: 2.4rem;
    justify-content: center;
    width: 2.8rem;
  }
}
