%placeholder {
  color: rgba($color-dark, 0.3) !important;
}

%genericFormComponent {
  background-color: $color-light;
  border: 0.1rem solid $color-quaternary-dark;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-dark;
  font-size: $fontsize-xl;
  font-weight: normal;
  line-height: 150%;
  padding: 0.5rem 1.6rem;
  padding-left: 5px;

  &::placeholder {
    @extend %placeholder;
  }
}

%avoid-blue-outline {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    -webkit-tap-highlight-color: none !important;
  }
}

%button-as-link {
  @extend %medium;
  background-color: initial !important;
  border: 0;
  color: $color-brand-base;
  display: inline;

  &:hover {
    background: none;
    cursor: pointer;
  }
}

%button-as-link-dark {
  @extend %button-as-link;
  @extend %avoid-blue-outline;

  color: $color-quaternary-darker;

  &:hover {
    color: inherit;
  }

  &:active {
    @extend %button-as-link;
    background-color: transparent !important;

    color: inherit !important;
  }
}

%interactiveZone {
  &:hover {
    background: $color-quaternary-light;
  }
}

%nonSmooth {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-smoothing: auto;
}

%resetElement {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

%disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
