@import '@scss/utils/_mixins';
@import '@scss/core-busuu/core-partials/_colors';

.search-used-in-locations {
  @include resetList();
  @include noSelect();
  $blockName: &;

  align-items: flex-end;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  max-width: 70%;

  &__headline {
    @extend .XS_12px_400;
    margin-bottom: $spacingBasic;
    text-align: right;
  }

  &__headline-toggle {
    cursor: pointer;
    margin-left: $spacingSmall;
  }

  &__locations {
    @include resetList();
    $blockName: &;

    align-items: flex-end;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ul {
      @include resetList();
    }

    #{$blockName}--multi {
      @include resetList();

      #{$blockName}__location {
        justify-content: flex-start;
      }
    }
  }

  &__location {
    @include resetList();
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2rem;
    width: 100%;

    &--too-many {
      @extend %bold;
      @extend .XS_12px_400;

      justify-content: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__location-segments {
    @include resetList();
    @extend .XS_12px_400;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0;

    * {
      color: $random-color-9;
      white-space: pre;
    }

    .navigate-icon {
      height: 1.3rem;
      width: 1.5rem;
    }

    .dropdown-item {
      background-color: transparent;
    }
  }

  &__location-segment {
    @extend .XS_12px_400;
    $blockName: &;
    color: inherit;
    margin: 0 $spacingBasic 0 0;

    &::after,
    &:last-child::after {
      background: url(~@static/svg/search/searchResults.svg) no-repeat !important;
      content: '';
      display: inline-block;
      height: 1.2rem;
      margin-left: $spacingBasic;
      position: relative;
      top: 0.2rem;
      width: 1rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  .dropdown-menu {
    max-height: 110px;
    overflow: auto !important;
    @include customStyledScrollbar();
  }
}
