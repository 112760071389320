@import '@scss/core-busuu/core-partials/_colors';

.searchable-selector {
  &__placeholder {
    margin: 0 !important;
    color: $color-quaternary-darker !important;
  }
  &__control {
    box-shadow: none !important;
    height: 44px;
    font-size: 18px;
    border-radius: 8px !important;
    border: none !important;

    &--is-focused {
      background: $color-quaternary-light !important;
    }

    &:hover {
      cursor: text;
      background: $color-quaternary-light;
    }
  }
  &__indicators {
    display: none !important;
  }
  &__menu {
    font-size: 18px;
    z-index: 4000 !important;
    border: 1px solid $color-quaternary-base;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05) !important;
    margin-top: 2px !important;
  }
  &__menu-list {
    padding: 0 !important;
  }
  &__option {
    &:hover {
      cursor: pointer;
      background-color: $color-quaternary-light !important;
    }
  }
}
