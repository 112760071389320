@import '@scss/core-busuu/core-partials/_colors';

.complete-message {
  margin-left: 15px;
  margin-top: 50px;

  &__caption {
    font-weight: 400;
    font-size: 14px;
    color: $color-quaternary-darker;
    margin-bottom: 6px;
  }
}
