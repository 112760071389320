@import '@scss/core-busuu/core-partials/_colors';

.preview__instruction {
  color: $color-brand-dark;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 16px;
  line-height: 1.7;
  font-weight: 800;

  &--for-tip {
    margin-top: unset;
    font-size: 28px;
    margin-bottom: 4px;
  }
}
