.exercise-dialogue {
  @include exercise-common-styles();
  @include creator('exercise-dialogue__creator', 'dialogues');
  @include contentCreatorWidgetLine('.standalone-menu');
  $blockName: &;

  &__creator-button {
    @include shineOnRollover(1.08);
    height: 7.8rem;
    width: 7.8rem;
  }

  &__creator__title {
    margin-bottom: 1.6rem;
  }

  &__characters-displayer,
  &__characters-displayer-intro {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__characters-displayer-list {
    @include resetList();
    @include genericTransition('opacity');
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__characters-displayer-list-item {
    margin-right: $spacingMedium;
    max-width: $dialogueCharacterSizeRegularSize;

    &:last-child {
      margin-right: 0;
    }
  }

  &__characters-displayer-intro &__characters-displayer-list-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $spacingMinimal;
    max-width: initial;
    width: 13rem;
  }

  &__characters-displayer-list-item-name {
    @extend .input-text;
    @include discoverableInputOnRollover();
    border: 0;
    color: $color-dark;
    font-size: 1.6rem;
    height: initial;
    margin-top: $spacingSmall;
    max-height: 4.8rem;
    max-width: inherit;
    min-height: 42px;
    padding: 0;
    resize: none;
    text-align: center;
    width: 13rem;

    &::placeholder {
      @extend %placeholder;
      font-size: inherit;
    }
  }

  &__creator {
    #{$blockName}__characters-displayer-list-item {
      margin-right: 0.4rem;
    }

    #{$blockName}__creator__title {
      margin-bottom: 2.4rem;
    }
  }

  &__dialogue-lines {
    @include resetList();
    padding-top: 1.6rem;
  }

  &__dialogue-line {
    align-items: flex-start;
    cursor: default !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.4rem;

    .dialogue-character {
      cursor: grab !important;
      margin-right: 1.6rem;
    }

    &--dragging {
      // TODO: Implement rotation effect when dragging. Captured in ticket https://busuucom.atlassian.net/browse/INTO-494

      .dialogue-character__add-icon-wrapper,
      .dialogue-character__draggable-icon-wrapper {
        opacity: 1 !important;
      }
    }
  }

  &__dialogue-line-contents {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &__dialogue-name-and-dialogue-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - #{$dialogueCharacterSizeMediumSize});
  }

  &__dialogue-name {
    @extend #{$blockName}__characters-displayer-list-item-name;
    @extend %normal;
    color: $color-quaternary-darker;
    font-size: 1.4rem;
    height: initial;
    margin-bottom: 0.4rem;
    padding: 0;
    padding-top: 0.4rem;
    text-align: left;
  }

  &__in-dialogue-creator {
    @extend %resetElement;
    @extend #{$blockName}__creator;
    @include genericTransition('opacity');
    border: 0 !important;
    height: initial !important;
    margin-top: 5.4rem;
    padding: 0 !important;

    #{$blockName}__characters-displayer-list {
      @include genericTransition('opacity');

      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }

  .standalone-menu__inner {
    max-width: 180px;
    opacity: 1;
    width: initial;
  }

  .standalone-menu {
    margin-top: 5.6rem;

    .standalone-menu__inner {
      @include genericTransition('opacity');
      height: $dialogueCharacterSizeSmallSize;
      opacity: 0;
      position: absolute;
      top: -#{calc($dialogueCharacterSizeSmallSize / 2)};

      &--1 {
        left: calc(50% - #{calc($dialogueCharacterSizeSmallSize / 2)});
      }

      &--2 {
        left: calc(50% - #{$dialogueCharacterSizeSmallSize});
      }

      &:hover {
        opacity: 1;
      }
    }

    .standalone-menu__line {
      opacity: 1;
    }

    .dialogue-character {
      margin-right: 0;
    }

    #{$blockName}__characters-displayer-list-item {
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__replace-character-wrapper {
    position: relative;
  }

  &__replace-character-background {
    @include genericTransition('opacity');

    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    height: $dialogueCharacterSizeMediumSize;
    opacity: 0;
    position: absolute;
    width: $dialogueCharacterSizeMediumSize;
    z-index: map-get($zIndices, 'exercise-dialogue__replace-character-background');

    &:hover {
      opacity: 1;
    }
  }

  &__replace-character {
    left: 2.8rem;
    position: absolute;
    top: 2.5rem;
    z-index: map-get($zIndices, 'exercise-dialogue__replace-character');
  }
}
