@import '@scss/core-busuu/core-partials/_colors';

.validation-displayer {
  display: flex;
  padding: 4px;
  width: fit-content;
  font-size: 12px;
  margin: 5px 0 10px 0;
  background: $color-secondary-3;
  border-radius: 4px;
  max-width: 70%;

  &--error {
    color: $color-secondary-base;
  }

  &--warning {
    color: $color-tertiary-base;
  }

  &--whole-width {
    max-width: unset;
  }

  &__icon {
    margin-right: 4px;
  }
}
