.dialogue-character {
  @mixin generateDialogueStyles($dialogueCharacterSize) {
    $addIconSize: 1.6rem;

    #{$blockName}__photo,
    #{$blockName}__preloader {
      @include genericTransition('opacity');
      @include shineOnRollover(1.2);
      border: 0.1rem solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      height: $dialogueCharacterSize;
      width: $dialogueCharacterSize;
    }

    #{$blockName}__border {
      pointer-events: none;

      &--visible,
      &--invisible {
        @include genericTransition();
        border: 0.4rem solid $color-brand-base;
        border-radius: 50%;
        display: block;
        height: calc(#{$dialogueCharacterSize} + 0.4rem);
        left: -0.2rem;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: -0.2rem;
        width: calc(#{$dialogueCharacterSize} + 0.4rem);
        z-index: map-get($zIndices, 'dialogue-character__border');
      }

      &--visible {
        border: 0.4rem solid $color-brand-base;
        opacity: 1;
      }

      &--invisible {
        opacity: 0;
      }
    }

    #{$blockName}__photo--selected {
      @include genericTransition('opacity');
      border: 0.1rem solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      height: $dialogueCharacterSize;
      width: $dialogueCharacterSize;
    }

    #{$blockName}__photo--non-rollovable {
      cursor: default;
      filter: initial;
      pointer-events: none;

      &:hover {
        filter: initial;
      }
    }

    #{$blockName}__photo--non-selectable {
      @extend #{$blockName}__photo--non-selectable;
      opacity: 0.5;
    }

    #{$blockName}__photo--show-as-active {
      @extend #{$blockName}__photo--non-selectable;
      opacity: 1 !important;
    }

    #{$blockName}__add-icon-wrapper,
    #{$blockName}__draggable-icon-wrapper {
      @include genericTransition();
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      cursor: pointer !important;
      height: $dialogueCharacterSize;
      opacity: 0;
      position: absolute;
      width: $dialogueCharacterSize;

      &:hover {
        opacity: 1;
      }
    }

    #{$blockName}__add-icon,
    #{$blockName}__draggable-icon {
      left: calc($dialogueCharacterSize / 2 - $addIconSize / 2);
      position: absolute;
      top: calc($dialogueCharacterSize / 2 - $addIconSize / 2);
    }

    #{$blockName}__draggable-icon {
      filter: brightness(2);
      left: 1rem;
      top: 1rem;
    }

    #{$blockName}__preloader-inner {
      @include preloaderAnimation();
      align-items: center;
      display: flex;
      height: calc($dialogueCharacterSize / 1.5);
      justify-content: center;
      left: 1.5rem;
      position: absolute;
      top: 1.5rem;
      width: calc($dialogueCharacterSize / 1.5);
    }
  }
  $blockName: &;
  position: relative;

  &--small {
    @include generateDialogueStyles($dialogueCharacterSizeSmallSize);
  }

  &--medium {
    @include generateDialogueStyles($dialogueCharacterSizeMediumSize);
  }

  &--large {
    @include generateDialogueStyles($dialogueCharacterSizeRegularSize);
  }
}
