@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.exercise-preview-for-activity-sidebar {
  min-width: 23rem;

  &__header {
    height: 8.5rem;
    font-weight: 800;
    font-size: 2.4rem;
    padding: 2.4rem;
    border-bottom: 0.1rem solid $color-quaternary-light-1;

    @include ellipsis();
  }

  &__navigation {
    height: calc(100% - 18rem);
    border: 0.1rem solid $color-quaternary-light-1;
    border-left: none;
    border-top: none;
    border-bottom: none;
    font-weight: 500;
    font-size: 1.4rem;

    &__items {
      height: 100%;
      padding: 2.6rem 2.4rem;
      overflow-y: scroll;
      width: 100%;

      @include customStyledScrollbar();

      &__item {
        margin-bottom: 1.6rem;

        &:hover {
          cursor: pointer;
        }

        &--active {
          font-weight: bold;
          color: $color-brand-base;
          pointer-events: none;
        }
      }
    }
  }
}
