.sync-status {
  align-items: center;
  display: none !important;
  display: flex;
  flex-direction: row;

  &__circle {
    border-radius: 50%;
    height: 1rem;
    margin-right: 1rem;
    margin-right: 1rem;
    width: 1rem;
  }

  &--red {
    background-color: red;
  }

  &--green {
    background-color: green;
  }

  &__since {
    display: block;
    font-size: 1.2rem;
  }
}
