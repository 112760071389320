@import '@scss/core-busuu/core-partials/_colors';

.user-item {
  color: $color-dark !important;
  padding: 20px 0 !important;
  min-height: 65px;

  &:hover {
    cursor: default !important;
  }

  &--position {
    color: $color-quaternary-darker;
  }

  &--edit-mode {
    background: $color-quaternary-light-5;
    grid-template-columns: 20% 10% 15% 15% 20% 20% !important;
  }

  &__edit-button {
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__selector {
    margin-right: 10px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      height: 48px;
      display: flex !important;
      align-items: center;
      border: 2px solid $color-brand-base !important;
      border-radius: 32px !important;
      margin: 0 10px 0 0 !important;
    }

    &__cancel-button {
      background-color: $color-light !important;
      color: $color-brand-base !important;
    }

    &__save-button {
      background: $color-brand-base;
      color: $color-light;
    }
  }

  a {
    display: flex;
  }
}
