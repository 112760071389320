.tooltip {
  position: fixed;
  font-size: 1.4rem;
  line-height: 1.8rem;
  max-width: 250px;
  z-index: map-get($zIndices, 'tooltip') !important;

  &-inner {
    border-radius: 0.4rem;
    padding: 1rem 1rem;
  }

  &:before {
    height: 0 !important;
  }
}

.tooltip-failed {
  background: $color-secondary-1 !important;
  color: $color-dark !important;
  padding: 5px !important;
  margin-top: -5px !important;

  &:after {
    border-top-color: $color-secondary-1 !important;
  }
}
