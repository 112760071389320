@include uploader('video-upload');

.video-upload {
  border: 0.2rem dashed $color-quaternary-base;
  height: 36rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &--with-file {
    &:hover {
      cursor: default;
    }
  }

  &__instructions__description {
    color: $color-dark;
  }

  img {
    width: auto;
  }

  video {
    border: 1px solid $color-quaternary-base;
    border-radius: 8px;
  }

  &--with-file {
    height: initial;
    border: none;
    width: fit-content;
  }

  &__download {
    @extend .video-upload__trash;
    right: 5rem;
  }

  &__instructions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__requirements {
    @extend %normal;

    color: $color-quaternary-darker;
    font-size: 1.2rem;
  }

  &--with-error {
    border-color: $color-secondary-base;

    &--failed {
      border-style: solid;
    }
  }

  &--processing {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 36rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &--disabled {
    height: 27rem;
    min-height: 27rem;

    & > div {
      height: 27rem !important;
    }
  }

  &--upload-in-progress {
    border: 1px solid $color-primary-lighter;
  }

  &__progress-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    progress {
      max-width: 50%;
      width: 100%;
      height: 8px;
      border: none;

      &::-webkit-progress-bar {
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      &::-webkit-progress-value {
        background-color: $color-primary-green-4;
        border-radius: 8px;
        transition: all 0.3s;
      }
    }
  }

  &--dragging {
    background: $color-secondary-4;
    border: 2px dashed $color-brand-base;
    border-radius: 16px;
  }
}
