.add-to-vocabulary-review {
  @include noSelect();

  align-items: center;
  color: rgba($color-quaternary-darker, 0.5);
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-start;
  pointer-events: none;

  &__add-to-vocabulary-checkbox {
    @extend .exercise-common-styles__add-to-vocabulary-checkbox;
  }

  &__text {
    position: relative;
    top: 0.1rem;
  }
}
