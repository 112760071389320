@import '@scss/core-busuu/core-partials/_colors';

.image-container {
  position: relative;
  margin-bottom: 16px;

  &__background {
    width: 100%;
    border-radius: 8px;
  }

  &__player {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  &--desktop {
    .image-container__background {
      height: 395px;
    }

    .image-container__player {
      width: 700px;
    }
  }

  &--tablet {
    .image-container__background {
      height: 280px;
    }

    .image-container__player {
      width: 500px;
    }
  }

  &--mobile {
    .image-container__background {
      height: 184px;
    }

    .image-container__player {
      width: 328px;
    }
  }
}

.audio-player {
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
}
