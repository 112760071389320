@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.dialogue-all-missed-words {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 0 16px;
  justify-content: center;
  margin-bottom: -50px;

  &--desktop {
    width: 700px;
    margin: auto;
  }

  &--tablet {
    width: 753px;
  }

  &--mobile {
    width: 361px;
    margin-bottom: 0;
    justify-content: unset;
    flex-wrap: unset;
    overflow: auto;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);

    @include customStyledScrollbar();
  }

  &__word {
    font-size: 16px;
    padding: 13px 27px;
    margin-right: 16px;
    margin-bottom: 17px;
    background: $color-light;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }
}

.dialogue-all-missed-words-container {
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
  max-height: 150px;
  overflow: auto;
  min-height: 84px;

  @include customStyledScrollbar();
}
