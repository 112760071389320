@import '@scss/core-busuu/core-partials/_colors';

.table-footer {
  display: flex;
  gap: 16px;
  align-items: center;

  &__caption {
    font-size: 14px;
    color: $color-quaternary-darker;
    margin-right: 8px;
  }

  &__row-selector {
    width: 80px;
    min-width: unset;
  }
}

.table-pagination {
  gap: 24px;

  .page-link {
    &:focus {
      box-shadow: none;
    }
  }

  &__arrow {
    &--disabled {
      opacity: 0.5;
    }

    .page-link {
      font-size: 30px;
      display: flex;
      align-items: center;
      padding: 0;
      height: 30px;
    }
  }

  &--active {
    .page-link.page-link {
      color: $color-brand-base;
    }
  }
}
