.table {
  thead th {
    border-bottom: 0.1rem solid $color-quaternary-base;

    span {
      cursor: pointer;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  tbody tr:hover {
    background: rgba($color-quaternary-dark, 0.3);
    transition: all 1.5s;
  }

  th {
    @include noSelect();
    font-size: 1.15rem;
    font-weight: normal;
  }

  td {
    vertical-align: middle;
  }
}
