.dialogue-character-with-number {
  position: relative;

  .number-indicator {
    bottom: 0.5rem;
    position: absolute;
    right: 1rem;
    z-index: map-get($zIndices, 'dialogue-character-with-number__number-indicator');
  }
}
