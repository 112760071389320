@import '@scss/core-busuu/core-partials/_colors';

.translations-panel-modal {
  height: 100vh;
  padding-bottom: $sidePanelFooterHeight;
  box-shadow: -0.1rem -0.1rem 0 $color-quaternary-base;
  @include customStyledScrollbar();

  &__translation-context-label {
    @extend .label-field;
  }

  &__translations-block,
  &__audios-block {
    display: block;
    visibility: visible;
    @include resetList();

    &--hidden {
      display: none;
      visibility: hidden;
    }
  }

  .inactive-languages-toggler {
    font-size: 16px;
    margin-top: 12px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-brand-base;

    .arrow-icon {
      margin-left: 8px;
    }

    &--hide {
      margin-bottom: 32px;
      border-top: 1px solid $color-quaternary-base;

      .arrow-icon {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__translation-context-label-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  &__translation {
    display: flex;
    flex-direction: column;
    height: unset;

    &--hidden {
      height: 1px;
    }
  }

  &__translation-data,
  &__audio {
    @include resetList();
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .flag {
      margin-top: 1rem;
    }

    &--for-audio {
      align-items: center;
    }

    &--with-error {
      margin-bottom: 0px;

      .translations-panel-modal__translation-text,
      .translations-panel-modal__translation-text-phonetic {
        .input-text:first-child {
          border-color: $color-secondary-base;
        }
      }
    }
  }

  &__translation-data {
    display: grid;
    grid-template-columns: 16% 84%;
    margin-bottom: 2rem;
  }

  &__audio {
    display: flex;
    flex-direction: column;

    .flag {
      margin-top: 0rem;
      margin-right: 0.8rem;
    }
  }

  &__translation-text,
  &__translation-text-phonetic,
  &__translator-context {
    @extend .input-text-wrapper;
  }

  &__translation-text--wysiwyg {
    border: none;
    width: 100%;
  }

  &__translation-text-phonetic {
    @extend .input-text-wrapper--two-boxes;
  }

  &__translation-text-phonetic {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bidimensional-editor--one-element {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__translation-text--ktag {
    border: 0;
    padding: 1rem 0;
  }

  &__translation-input,
  &__translator-context,
  &__translation-area {
    @include applyFocus();
    @extend .input-text;

    &:disabled {
      @include disableApplyFocus();
    }
  }

  &__translation-area {
    height: 8rem;
  }

  &__translator-context {
    height: 7rem;
  }

  &__inactive-languages-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 2.4rem;
  }

  &__inactive-languages-button {
    @extend %button-as-link;

    margin-bottom: 2rem;
  }

  &__translation-phonetic-icon {
    bottom: 2rem;
    position: absolute;
    right: 2rem;
  }

  &__alert {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2.4rem;

    &:hover {
      .translations-panel-modal__other-locations-icon {
        cursor: pointer;
        transform: translateX(0.2rem);
        transition: all #{$animationTime};
      }
    }
  }

  &__alert-icon {
    margin-right: 0.5rem;
  }

  &__other-locations {
    @include resetList();
  }

  &__other-location {
    align-items: center;
    border-bottom: 0.1rem solid $color-quaternary-base;
    color: $color-dark;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.6rem;
    justify-content: flex-start;
    line-height: 150%;
    padding: 1.6rem 0;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__other-location-text {
    cursor: pointer;
    margin-right: 0.5rem;
  }

  &__other-location-link {
    color: inherit;

    &:hover {
      text-decoration: inherit;
    }
  }

  &__other-locations-text {
    margin-right: 0.25rem;
  }

  &__other-locations-icon {
    position: relative;
    top: 0.1rem;
    transition: all #{$animationTime};
  }

  &__dropdown-icon {
    margin-left: 0.5rem;
  }

  &__save-cancel {
    @include modalDimensions();

    align-items: center;
    background: $color-light;
    bottom: 0;
    box-shadow: -0.1rem -0.1rem 0 $color-quaternary-base;
    display: flex;
    height: $sidePanelFooterHeight;
    justify-content: flex-end;
    padding: 2rem;
    position: fixed;
    right: 0;
    z-index: map-get($zIndices, 'translations-panel-modal__save-cancel');

    .btn {
      font-size: 1.6rem;
    }
  }

  &__cancel {
    @extend %button-as-link-dark;
  }

  .nav-tabs {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .nav-tabs .nav-link {
    @extend %normal;

    font-size: 1.6rem;
    margin-right: 2rem;
    padding: 0;

    &.active {
      @extend %normal;
    }

    &:last-child {
      margin-right: 0;
    }

    &.disabled {
      opacity: 0.25;
    }
  }

  &__other-location-external-link-icon {
    margin-left: 0.3rem;
    position: relative;
    top: -0.3rem;
  }

  &__translation-text-ktag {
    background-color: $color-light;
    border: 0.1rem dashed $color-quaternary-base;
    border-radius: 0.4rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.4rem;
    white-space: nowrap;
  }

  .error {
    margin: $spacingSmall 0;
  }
}
