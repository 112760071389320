.gapping-tool {
  @extend .ktags-with-audio-creator;
  position: relative;

  &__characters {
    font-size: 1.2rem;
    position: absolute;
    right: $spacingMedium;
    top: 1rem;
    z-index: map-get($zIndices, 'gapping-tool__characters');
  }

  &__available-characters {
    font-size: 1.2rem;
    margin-left: $spacingSmall;

    &--exceeded {
      color: $color-secondary-base;
    }
  }

  .ktags-creator {
    border-bottom: 0.1rem solid $color-quaternary-base;
    border-bottom-left-radius: $genericBorderRadius;
    border-bottom-right-radius: $genericBorderRadius;
  }

  .DraftEditor-root * {
    word-spacing: $spacingTiny;
  }
}
