@import '@scss/core-busuu/core-partials/_colors';

.navigation-warning-modal {
  .modal-content {
    border-radius: 16px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    min-height: 242px;
    align-items: center;
    padding: 40px;
  }

  &__title {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 16px;
    color: $color-quaternary-darker;
    margin-bottom: 32px;
    text-align: center;
    max-width: 80%;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-self: end;
  }
}
