.activity-search-result {
  &__icon {
    margin-right: $spacingMedium;
  }

  &__activity-type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
