.show-to-users-in-course-language {
  @include noSelect();

  color: $color-quaternary-darker;
  cursor: pointer;
  font-size: 1.4rem;

  &__text {
    position: relative;
    top: 0.1rem;
  }

  &--disabled {
    @extend %disabled;
  }
}
