@import '@scss/core-busuu/core-partials/_colors';

.issues-toggler {
  margin-right: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 32px;
  color: $color-dark;

  &--not-validated {
    background: $color-quaternary-base;
  }

  &--success {
    background: $color-primary-green-1;
  }

  &--warning {
    background: $color-secondary-2;
  }

  &--error {
    background: $color-secondary-1;
  }

  &--has-invalid-children {
    background: $color-secondary-2;
  }

  &:hover {
    cursor: pointer;
  }

  &__icon {
    margin-right: 9px;
  }

  &__text {
    font-weight: bold;
    font-size: 16px;
  }
}
