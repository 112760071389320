@import '@scss/core-busuu/core-partials/_colors';

.context-menu {
  @include noSelect();
  align-items: center;
  animation: none;
  background: transparent;

  cursor: pointer;
  display: block;
  display: flex;
  font-size: 1.6rem;
  font-size: 2rem;
  justify-content: flex-end;
  list-style: none;
  list-style: none;
  margin: 0;
  // width: 1rem;
  // overflow: hidden;
  padding: 0;
  transition: none;

  #dropdown-button-drop-left {
    width: initial;
  }

  #dropdown-button-drop-left::before {
    display: none;
  }

  .dropdown {
    font-weight: bold;
    width: 1rem;
  }

  .dropdown-menu {
    border: 1px solid $color-quaternary-base;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 0;
  }

  .dropdown-toggle {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $random-color-2;
    font-size: 24px;
    padding: 0;
    width: 1rem;
    transition: none;

    &::after {
      display: none;
    }
  }

  &.show .btn-secondary.dropdown-toggle {
    background: transparent;
    border: 0;
    box-shadow: none;
    font-size: 24px;
  }

  .dropdown-item {
    font-size: 1.4rem;
    padding: 1rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__list-element {
    display: flex;

    img {
      margin-right: 1rem;
    }

    &__new-mark {
      align-self: center;
      background-color: $color-primary-green-4;
      border-radius: 0.4rem;
      color: $color-light;
      font-size: 1rem;
      font-weight: 700;
      height: 1.8rem;
      line-height: 1.8rem;
      margin-left: 0.8rem;
      padding: 0 0.8rem;
      text-transform: uppercase;
    }
  }

  &--search-results {
    .dropdown-menu {
      .search-used-in-locations__locations {
        width: 360px;
        padding: 1rem;
      }

      .dropdown-item {
        width: 15px;
        padding: 0;
      }
    }
  }
}
