@import '@scss/core-busuu/core-partials/_colors';

.fillgap {
  &-text {
    display: flex;
    flex-wrap: wrap;
    line-height: 50px;
    margin-bottom: 40px;

    &__word {
      font-size: 24px;
      margin-right: 8px;
      font-weight: 800;
    }

    &__missed-word {
      min-width: 135px;
      height: 50px;
      background-color: $color-light;
      border: 1px dashed $color-brand-base;
      border-radius: 8px;
      font-size: 16px;
      justify-content: center;
      margin: 0 8px 4px 0;

      &--correct {
        min-width: 135px;
        display: flex;
        align-items: center;
        color: $color-light;
        border: none;
        background-color: $color-primary-base;
        padding: 10px 20px;
        line-height: 1.7;

        .fillgap-text__word {
          font-size: 16px;
          margin: 0;
          font-weight: 400;
        }
      }

      &--incorrect {
        min-width: unset;
        padding: 0 10px;
        display: flex;
        align-items: center;
        background-color: $color-secondary-base;
        color: $color-light;
        border: none;

        .fillgap-text__word {
          margin: 0;
        }
      }
    }

    &__empty-place {
      width: 135px;
      height: 48px;
      margin-right: 8px;
      fill: $color-light;
      stroke: $color-quaternary-dark;
      stroke-width: 4px;
      stroke-dasharray: 8, 8;
    }
  }

  &-words {
    display: flex;
    flex-wrap: wrap;
  }

  &-word {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px 20px;
    font-size: 16px;
    background: $color-light;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 16px 16px 0;

    &--answered {
      border: 2px solid $color-quaternary-base;
      color: $color-light;
      box-shadow: none;
    }
  }
}
