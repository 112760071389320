@import '@scss/core-busuu/core-partials/_colors';

.true-false-text {
  font-size: 28px;
  margin-bottom: 16px;
}

.true-false-statement-preview {
  font-size: 18px;
  margin-bottom: 40px;
}

.true-false-buttons {
  display: flex;
  justify-content: space-between;

  &--mobile {
    flex-direction: column;

    .true-false-button-view {
      margin: 0 0 16px 0;
    }
  }
}

.true-false-button-view {
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: $color-light;
  color: $color-dark;
  margin: 0 8px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--correct {
    background-color: $color-primary-base;
    box-shadow: none;
    color: $color-light;
  }

  &--incorrect {
    background-color: $color-secondary-base;
    box-shadow: none;
    color: $color-light;
  }
}
