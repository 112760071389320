@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.recap-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color-quaternary-darker;
  display: inline-block;
  font-size: 1.4rem;
  margin-bottom: 3px;
  margin-top: 10px;

  &__box-title {
    color: $color-quaternary-darker;
    display: inline-block;
    font-size: 1.4rem;
  }
}

.recap-tooltip-image {
  margin-right: 6px;
}

.recap-selector {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 96px;
  background: $color-light;
  border: 1px solid $color-quaternary-base;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  color: $color-dark;

  &:hover {
    cursor: pointer;
  }

  &--opened {
    border: 1px solid $color-brand-base;

    &:hover {
      border: 1px solid $color-brand-base;
    }
  }

  .id-displayer {
    margin: 0;
  }

  .content-common-header__content__controllers {
    margin-left: 70px;
  }
}

.arrow-image {
  position: absolute;
  right: 10px;
  transition: all 0.3s linear;

  &--opened {
    transform: rotate(180deg);
  }
}

.recap-options {
  margin-top: 3px;
  border: 1px solid $color-quaternary-base;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  &__item {
    font-size: 16px;
    &--active {
      background: $color-quaternary-light-3;
      font-weight: 800;
    }

    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    padding: 16px;

    &:hover {
      background: $color-quaternary-light-2;
    }

    .id-displayer {
      margin: 0;
    }

    .content-common-header__content__controllers {
      margin-left: 70px;
    }
  }
}
