@import '@scss/core-busuu/core-partials/_colors';

.bidimensional-editor {
  $cellsBorderRadius: 0.7rem;
  $rowSelectorWidth: 0.6rem;
  $rowSelectorHeight: $rowSelectorWidth + 0.2rem;
  $editorPerCellHeight: 4rem;
  position: relative;

  .rdw-emoji-modal {
    padding: 0.5rem;
  }

  &--one-element {
    border-radius: 8px;

    border: 0.1rem solid $color-quaternary-base;
    @include applyFocus();

    .editor__wrapper {
      outline: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rdw-editor-main {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rdw-emoji-modal {
      width: 28rem;
    }

    &--with-error {
      border: 1px solid $color-secondary-base;
    }

    .bidimensional-editor__box-table-wrapper {
      border: none;
    }

    .editor-0,
    .editor-1,
    .editor-2,
    .bidimensional-editor__editor-column-selector-wrapper {
      border-radius: 8px;
    }
  }

  &__add-row,
  &__add-column {
    cursor: pointer;
    opacity: 0;
    transition: all #{$animationTime};

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &__add-column {
    position: absolute;
    right: var(--right);
    top: 3.5rem;
    z-index: map-get($zIndices, 'bidimensional-editor__add-column');
  }

  &__add-column-tip {
    position: absolute;
    right: var(--right);
    top: 48px;
    z-index: map-get($zIndices, 'bidimensional-editor__add-column-tip');
  }

  &__add-row {
    bottom: var(--bottom);
    left: -24px;
    position: absolute;
    z-index: map-get($zIndices, 'bidimensional-editor__add-row');
  }

  &__add-row-tip {
    position: absolute;
    bottom: var(--bottom);
    left: -12px;
    z-index: map-get($zIndices, 'bidimensional-editor__add-row-tip');
  }

  &__box-table-wrapper {
    align-items: center;
    border: 0.1rem solid $color-quaternary-base;
    border-radius: $genericBorderRadius;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 4.5rem;
    position: relative;

    .rdw-editor-toolbar {
      border-radius: $cellsBorderRadius;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }

    .editor-0,
    .editor-1,
    .editor-2,
    .bidimensional-editor__editor-column-selector-wrapper {
      background: $color-light;
      border-bottom: 0;
      width: 100%;
    }

    .bidimensional-editor__editor-column-selector-wrapper--bidimensional-enabled {
      border: 0.1rem solid $color-quaternary-base;
    }

    &--bidimensional-enabled {
      padding-top: 5.5rem;
      background: $color-light;
    }

    &--0 {
      .bidimensional-editor__editor-row-selector-wrapper {
        border-bottom-left-radius: $cellsBorderRadius;
        border-bottom-right-radius: $cellsBorderRadius;
        width: 100%;
      }
    }

    &--1 {
      .bidimensional-editor__editor-row-selector-wrapper {
        width: 50%;
      }
    }

    &--2 {
      .bidimensional-editor__editor-row-selector-wrapper {
        width: 33.333333%;
      }
    }
  }

  &__row-selector,
  &__column-selector {
    background: $color-quaternary-light;
    position: relative;

    &--enabled {
      background: $random-color-3;
    }

    &--cursor {
      cursor: pointer;
    }
  }

  &__row-selector {
    height: $editorPerCellHeight;
    width: $rowSelectorWidth;

    &--first {
      position: relative;
      top: 0.5rem;
    }
  }

  &__editor-column-selector-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__column-selector {
    height: $rowSelectorHeight;
    width: 100%;
  }

  &__editor-row-selector-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__editor {
    &--selected {
      background: $random-color-4;
      border: 0.1rem solid $color-brand-base;
    }
  }

  &__column-selector-remove-icon {
    @include increaseBrightness();

    display: none;
    left: calc(50% - 2.4rem);
    position: absolute;
    top: -3rem;
    z-index: map-get($zIndices, 'bidimensional-editor__column-selector-remove-icon');

    &--enabled {
      display: block;
    }
  }

  &__row-selector-remove-icon {
    @include increaseBrightness();

    display: none;
    left: -3rem;
    position: absolute;
    top: 0.5rem;
    z-index: map-get($zIndices, 'bidimensional-editor__row-selector-remove-icon');

    &--enabled {
      display: block;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  .bidimensional-editor__editor-wrapper {
    left: 0;
    top: 0;
    width: 100%;

    &--highlighted .DraftEditor-editorContainer > div,
    &--highlighted .public-DraftEditorPlaceholder-root > div {
      background-color: $color-warning;
    }
  }

  &__characters-counter {
    font-size: 12px;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;

    &--warning {
      color: $color-secondary-warning;
    }
  }

  &--single-line {
    margin-bottom: 0.8rem;

    .rdw-editor-main {
      height: 4rem !important;
      line-height: 1.8;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .DraftEditor-editorContainer {
        font-size: 1.8rem;
      }

      .public-DraftStyleDefault-block {
        white-space: nowrap;
      }
    }
  }
}

.rdw-editor-main {
  background: $color-light;
}
