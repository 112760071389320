@import '@scss/core-busuu/core-partials/_colors';

.typing-hint {
  font-size: 20px;
  margin-bottom: 24px;
}

.typing-text {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  line-height: 42px;

  &__word {
    font-size: 28px;
    margin-right: 9px;
  }

  &__missed-word {
    color: $color-quaternary-dark;
    border-bottom: 2px solid $color-quaternary-dark;

    &--answered {
      display: flex;
      align-items: center;
    }

    &--correct {
      .typing-text__word {
        color: $color-dark;
      }
    }

    &--incorrect {
      .typing-text__word {
        color: $color-dark;
      }
    }
  }
}
