@import '@scss/core-busuu/core-partials/_colors';

.course-composer {
  margin-left: -15px;
  margin-right: -15px;

  @for $i from 0 through 200 {
    .el-#{$i} {
      @if $i == 0 {
        z-index: map-get($zIndices, 'course-composer__el');
      } @else {
        @if $i == 1 {
          z-index: map-get($zIndices, 'course-composer__el') - 2;
        } @else {
          z-index: map-get($zIndices, 'course-composer__el') - 2 - (2 * ($i - 1));
        }
      }
    }
  }

  position: relative;

  .droppable-wrapper {
    margin-left: 15px;
    margin-bottom: 20px;
    .container {
      padding: 0px;
    }
  }

  &__base-content-drag-wrapper {
    opacity: 1;
    position: relative;
    transition:
      opacity #{$animationTime},
      backdrop-filter #{$animationTime};
  }

  .btn {
    cursor: pointer;
  }

  > .course-edition__first-top-element .content-common-header__activity-details {
    position: relative;
    top: -0.3rem;
    padding: 10px 0;

    .id-displayer {
      margin-bottom: 0.6rem;
    }

    .content-common-header__description {
      @extend .MD_18px_500;
      border: 0;
      color: $color-quaternary-darker;
      width: 100%;
    }

    .content-common-header__content-title {
      display: flex;
      align-items: center;
      font-size: 3rem;
      margin-bottom: 1.4rem;
    }
  }
}
