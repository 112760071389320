.base-content {
  background: $color-light;
  display: flex;
  margin: 0;
  position: relative;

  &:hover {
    .base-content__main-wrapper {
      cursor: pointer;
      background: $color-quaternary-light-2;
      box-shadow:
        0px 1px 0px $color-quaternary-base,
        inset 0px 1px 0px $color-quaternary-base;
    }
    .base-content__main-wrapper > div {
      border-color: $color-quaternary-light-4;
    }
  }

  &__enter {
    margin-left: 2rem;
    transition: filter #{$animationTime};

    &:hover {
      filter: brightness(110%);
    }
  }

  &__main-wrapper {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    padding-left: $spacingBasic;

    & > div {
      width: 100%;
      padding: 10px 0;
    }

    &--dragging,
    &--new-element {
      box-shadow:
        0px 1px 0px $color-quaternary-base,
        inset 0px 1px 0px $color-quaternary-base;
      background: $color-quaternary-light-3;
    }

    &--tooltip-helper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  input {
    border: 0;
  }

  &__name {
    align-items: center;
    display: flex;
    font-size: 2rem;
    justify-content: flex-start;
    text-transform: capitalize;
  }

  &__content {
    font-size: 1.4rem;
  }

  &--view-mode {
    margin-bottom: 3rem;
  }

  &__description {
    @extend .MD_18px_500;
    border: 0;
    color: $color-quaternary-darker;
    width: 100%;
  }

  &__options-and-link {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    &__remove-button {
      margin-right: 10px;
    }

    &__copy-button {
      margin-right: 10px;

      &:hover {
        cursor: not-allowed;
      }
    }

    &__empty-remove-button {
      width: 24px;
      margin-right: 10px;
    }

    header {
      margin-right: 2rem;
    }
  }

  &__publishing-status {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;

    img {
      width: 16px;
      height: 21px;
    }

    &__image {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $color-secondary-base;

      &--ready {
        background: $color-primary-green-2;
      }
    }
  }
}

.course-content,
.level-content,
.lesson-content,
.activity-content,
.exercise-content {
  align-self: flex-end;
  position: relative;
}

.level-content {
  .base-content {
    padding-bottom: 0;
  }
}

.course-edition__first-top-element {
  margin-left: 15px;

  .base-content__main-wrapper {
    padding-left: 0px;
  }

  .base-content {
    &:hover {
      .base-content__main-wrapper {
        cursor: default;
        background: none;
        box-shadow: none;
      }
      .base-content__main-wrapper > div {
        border: none;
      }
    }
  }
}
