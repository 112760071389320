.headline1,
.headline2,
.headline3 {
  @extend %medium;
  font-size: 1.8rem;

  font-style: normal;
  font-weight: bold;
  line-height: 130%;
}

.headline3 {
  color: $color-dark;
}

.Web_Section_subheader {
  @extend %bold;
  @extend %nonSmooth;

  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 3.1rem;
}

.LG_18px_800 {
  @extend %bold;
  @extend %nonSmooth;

  font-size: 1.8rem;
  font-style: normal;
  letter-spacing: 0em;
  line-height: 2.7rem;
}

.LG_18px_400 {
  @extend %nonSmooth;

  font-size: 1.4rem;
  font-style: normal;
  letter-spacing: 0em;
  line-height: 2.7rem;
}

.MD_16px_500 {
  @extend %nonSmooth;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.4rem;
}

.MD_14px_700 {
  @extend %nonSmooth;
  @extend %bold;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 2.4rem;
}

.MD_14px_500 {
  @extend %nonSmooth;
  @extend %normal;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 2.4rem;
}

.MD_18px_500 {
  @extend %nonSmooth;
  @extend %normal;
  @extend .MD_14px_500;

  font-size: 1.8rem;
}

.MD_14px_400 {
  @extend %nonSmooth;
  @extend %normal;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.4rem;
}

.XS_12px_400 {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.8rem;
}

.SM_14px_800_CAPS {
  @extend %bold;
  @extend %nonSmooth;

  color: $color-quaternary-darker;
  font-size: 1.4rem;
  font-style: normal;
  letter-spacing: 0.02rem;
  letter-spacing: 0.02em;
  line-height: 2.1rem;
  text-transform: uppercase;
}

.MD_16px_400 {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.4rem;
  text-align: left;
}

.Web_Body_text {
  color: $color-quaternary-darker;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.4rem;
}
