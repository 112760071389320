@import '@scss/core-busuu/core-partials/_colors';

.publishing-status {
  margin-bottom: 8px;
  width: fit-content;

  &__value {
    margin-left: 8px;
    border-radius: 4px;
    padding: 4px 12px;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: $color-light;
    background-color: $color-quaternary-dark;
  }
}
