@import '@scss/core-busuu/core-partials/_colors';

.course-edition {
  $blockName: &;

  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  > .row {
    min-height: 100vh;
  }

  &__logo-navigator {
    background: $color-quaternary-light;
    height: 100%;
    min-height: 100vh;
    padding: 0 1rem;
    position: fixed;
    width: $logosCourseNavigationWidth;
  }

  &__course-data-composer {
    background: $color-light;
    left: $logosCourseNavigationWidth;
    margin-top: 10.5rem;
    min-height: inherit;
    padding: 0 6rem;
    padding-bottom: 5rem;
    position: relative;
    width: calc(100% - #{$logosCourseNavigationWidth});

    &.collapsed {
      left: $logosCourseNavigationCollapsedWidth;
      width: calc(100% - #{$logosCourseNavigationCollapsedWidth});

      & > .header {
        left: $logosCourseNavigationCollapsedWidth;
        width: calc(100% - #{$logosCourseNavigationCollapsedWidth});
      }
    }
  }

  &__logo {
    height: 4.8rem;
  }

  &__id,
  &__course-language {
    color: $color-quaternary-darker;
  }

  &__id {
    @extend %medium;
  }

  &__header {
    margin-bottom: 2.4rem;
  }

  &__checkpoint-name,
  &__checkpoint-description {
    @include discoverableInputOnRollover();
    padding: 0.2rem 0.4rem;
    padding-left: 5px;

    &:hover {
      background: transparent;
    }
  }

  &__checkpoint-name {
    @extend %bold;
    border: 0;
    color: $color-dark;
    font-size: 3rem;
    margin-bottom: $spacingSmall;
  }

  &__checkpoint-description {
    border: 0;
    color: $color-quaternary-darker;
    font-size: 1.8rem;
  }

  &__file-upload {
    @extend .d-flex;
    @extend .align-items-center;
    flex-direction: column;
    padding-right: 0px;
  }

  &__back-link {
    display: inline-block;
    font-size: 1.2rem;
    left: calc(#{$logosCourseNavigationWidth} - 7rem);
    margin-bottom: 5rem;
    position: fixed;
    top: 1rem;
  }

  &__ready-to-publish {
    margin-top: 1rem;
  }

  .btn {
    font-size: 1.4rem;
  }

  &__publish-status-and-other-places-container {
    display: flex;
  }
}

.backlink {
  display: inline-block;
  font-size: $fontsize-xl;
  margin: 3.6rem 0;
}
