.queueItemFlag {
  padding: 1px 8px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.button.button {
  border-radius: 999px;
  padding: 8px 16px;
}
