.App {
  display: flex;
  width: 100%;

  header {
    .profile__menu {
      .dropdown-menu.show {
        transform: translate3d(-220px, 45px, 0px) !important;
      }
    }
  }
}
