@import '@scss/utils/_mixins';
@import '@scss/core-busuu/core-partials/_colors';

.upload-translations-modal {
  @include modalClosable();

  $borderRadius: 1.6rem;

  .modal-content {
    border-radius: $borderRadius;
    width: 56rem;
  }

  .modal-body {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    padding: 0;
    padding: 4rem;
    padding-top: 0rem;
  }

  .upload-translations {
    $blockName: &;

    &__buttons-container {
      align-items: center;
      display: flex;
      justify-content: center;

      .btn:first-child {
        margin-right: $spacingBasic;
      }
    }

    &__browse-filename {
      max-width: 75%;
    }

    &__phase {
      display: none;
    }

    &__phase--visible {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__preloader {
      @include preloaderAnimation();

      margin-bottom: 4.8rem;
    }

    &__title {
      @extend %bold;
      color: $color-dark;
      margin-bottom: $spacingBasic;

      &--error {
        background: url(~@components/UploadTranslations/img/error.svg) no-repeat left center;
        padding-left: $spacingLarge;
      }
    }

    &__explanation-errors {
      max-height: 300px;
      overflow: auto;
      margin-bottom: 10px;
      padding: 8px;

      @include customStyledScrollbar();

      .upload-translations {
        margin-bottom: 12px;
      }
    }

    &__explanation,
    &__explanation-error {
      color: $color-quaternary-darker;
      font-size: 1.8rem;
      line-height: 150%;
      margin-bottom: $spacingLarge;
      text-align: center;
    }

    &__explanation-error {
      @extend %bold;

      font-size: 1.6rem;
      text-align: left;
      width: 100%;
    }

    &__browse-container {
      align-items: center;
      background: $color-quaternary-light;
      border: 0.1rem solid $color-quaternary-base;
      border-radius: 0.8rem;
      cursor: pointer;
      display: flex;
      font-size: 1.4rem;
      height: 8.8rem;
      justify-content: center;
      margin-bottom: $spacingLarge;
      min-width: 48rem;
      padding: $spacingMedium;
    }

    &__browse-container--open {
      justify-content: space-between;
    }

    &__cta {
      color: $color-brand-base;
      cursor: pointer;
      font-size: 1.8rem;
    }

    &__browse {
      display: none;
    }

    &__browse--visible {
      display: block;
    }

    &__phase1 {
      padding: 4rem;
      padding-top: 6.4rem;

      .btn {
        @extend %bold;
        background: $color-light;
        border: 0.2rem solid $color-quaternary-base;
        border-radius: calc($borderRadius / 2);
        color: $color-quaternary-darker;
      }
    }

    &__phase2 {
      .upload-translations__title {
        @extend %normal;
        margin-bottom: 3.2rem;
      }
    }

    &__download-complete {
      margin-bottom: 2rem;
    }
  }
}
