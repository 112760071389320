.search-box {
  @extend %genericFormComponent;
  @extend .p-0;
  $blockName: &;
  align-items: center;
  border: 0.1rem solid $color-quaternary-base;
  display: flex;
  justify-content: center;
  line-height: 100%;
  min-height: 3.9rem;
  position: relative;

  &__filter {
    padding: 0;
    width: auto;
  }

  &__filter:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
  }

  &__icon {
    left: 1rem;
    position: absolute;
    top: 1rem;
    opacity: 0.5;
  }

  &__icon-close {
    cursor: pointer;
    height: 2rem;
    position: absolute;
    right: 0.5rem;
    top: $spacingBasic;
    width: 2rem;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }

  .form-control {
    @extend %genericFormComponent;
    border: 0;
    border-radius: 0;
    padding-left: 0rem;
    width: calc(100% - 6rem);
  }

  &--big {
    @extend .p-0;
    align-items: center;
    display: flex;
    height: 4.8rem !important;
    justify-content: center;
    width: 23.5rem;

    #{$blockName}__icon {
      left: 1.4rem;
      top: initial;
      transform: scale(1.2);

      svg {
        fill: rgba($color-dark, 0.3);
      }
    }

    .form-control {
      font-size: 1.8rem !important;
      padding-left: $spacingBasic;
      padding-right: 0rem;
      text-align: left;
    }
  }

  &--readOnly {
    cursor: pointer;

    * {
      cursor: inherit;
    }
  }

  &--applyFocus {
    @include applyFocus();
  }
}
