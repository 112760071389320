.audio-controller {
  margin: auto;
  margin-bottom: 24px;

  &__playback {
    width: 28px;
    height: 22px;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__play {
    width: 50px;
    height: 50px;
    margin: 0 18px;
  }
}
