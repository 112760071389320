.exercise-speech-recognition {
  @include exercise-common-styles();

  &__selected-phonemes {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__selected-phoneme {
    margin-right: $spacingBasic;
    width: 9.6rem !important;
    padding: 2px 8px !important;
  }
}
