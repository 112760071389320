.ready-to-publish {
  cursor: pointer;

  &__checkbox {
    margin-right: 1rem;
    position: relative;
    top: 0.3rem;
  }

  &__text {
    color: $color-dark;
    font-size: 1.6rem;
    position: relative;
  }
}
