@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.alternative-value-creator {
  margin-top: 16px;

  &__value {
    &__main {
      display: flex;
      align-items: center;
      padding: 0 8px;
      border: 1px solid $color-quaternary-base;
      font-size: 16px;
      border-right: none;
      border-radius: 8px 0 0 8px;
      color: $color-dark;
      height: 44px;
      width: 200px;
      overflow: auto;
      white-space: nowrap;

      @include customStyledScrollbar();
    }
  }
}

.basic-multi-select {
  width: 100%;
}

.select {
  &__control {
    border: 1px solid $color-quaternary-base !important;
    border-radius: 8px !important;
    font-size: 18px;
    min-height: 44px !important;
    width: 100%;

    &:hover {
      cursor: text;
      border-color: $color-quaternary-base;
    }

    &--is-focused {
      box-shadow: none !important;
      border: 1px solid $color-brand-base !important;
    }
  }

  &__multi-value {
    display: flex;
    align-items: center;
    padding: 0 4px;
    border: 1px solid $color-brand-base;
    border-radius: 12px !important;
    background-color: $color-light !important;

    &__label {
      color: $color-brand-base !important;
    }

    &__remove {
      width: 14px;
      height: 14px;

      background: url(~@static/svg/Trash.svg) no-repeat;
      background-size: cover;
      border: 0;
      opacity: 1;

      & > svg {
        display: none !important;
      }

      &:hover {
        background-color: unset !important;
        color: unset !important;
      }
    }
  }

  &__menu {
    display: none !important;
  }

  &__clear-indicator {
    &:hover {
      cursor: pointer;
    }
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    display: none !important;
  }
}
