@import '@scss/core-busuu/core-partials/_colors';

.link-to-roles-container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 14px 18px;
  background: $color-quaternary-light-5;
  border-radius: 12px;
  font-size: 14px;
  margin-bottom: 32px;

  &__image {
    margin-right: 12px;
  }

  a {
    color: $color-brand-base;

    &:hover {
      color: $color-brand-base;
      text-decoration: none;
    }
  }
}
