@import '@scss/core-busuu/core-partials/_colors';

.content-common-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  header {
    @extend .d-flex;
    @extend .justify-content-between;
  }

  &__card-image {
    margin-right: 2rem;
    width: 55px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__empty-card-image {
    margin-right: 2rem;
    width: 55px;
  }

  &__audio-image-distractor {
    width: 1px;
    height: 10px;
    background: $color-quaternary-dark;
    margin-right: 16px;
  }

  &__empty-audio-image-distractor {
    width: 1px;
    margin-right: 16px;
  }

  &__empty-audio-image {
    width: 24px;
  }

  &--activity {
    width: 100%;

    .content-common-header__activity-details-with-icon {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-top: 0.3rem;
    }

    .content-common-header__activity-details {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .dropdown-menu.show {
        transform: translate3d(0, 34px, 0px) !important;
      }
    }

    .content-common-header__description {
      color: $color-quaternary-darker;
      font-size: 1.4rem;
    }

    .content-common-header__id {
      display: block;
      margin-bottom: 1rem;
    }

    .content-common-header__id-name {
      cursor: pointer;
      display: block;
      font-size: 1.2rem;
      margin-bottom: 0.25rem;
    }
  }

  &__content-title {
    &--fixed-height {
      height: 28px;
    }
  }

  .content-common-header__content-title {
    font-size: 1.6rem;
    width: 100%;
    align-items: center;
    display: flex;

    &__common-text {
      text-transform: uppercase;
      font-size: 12px;
      color: $color-quaternary-darker;
    }

    &__validation-mark {
      margin-left: 10px;
    }

    &__common-text--bigger {
      font-size: 14px;
      color: $color-quaternary-darker;
      max-width: 450px;
      max-height: 24px;

      @include ellipsis();
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &__excercise-data {
      display: flex;
    }

    &__controllers {
      display: flex;
      align-items: center;

      &__three-dots {
        display: flex;
        margin-right: 10px;
        visibility: hidden;

        &--visible {
          visibility: visible;
        }
      }
    }

    &__image-container {
      display: flex;
      width: 80px;
      height: 80px;
      background: $color-quaternary-light;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    &__image {
      width: 24px;
    }

    &__excercise-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--exercise {
      flex-direction: row;
      align-items: center;
    }

    &__id-container {
      display: flex;

      &__status {
        display: flex;
        margin-left: 14px;
        align-items: center;

        &__text {
          margin-left: 6px;
          color: $color-quaternary-darker;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }

  &__content-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__content-title__id {
    @extend .XS_12px_400;
    @extend %normal;

    strong {
      @extend %bold;
    }
  }

  &__content-and-image {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    &__static-image {
      margin-right: 1.2rem;
    }
  }

  &__file-upload {
    margin-right: 2rem;
  }

  &__id,
  &__id-name {
    color: $color-quaternary-darker;
    font-size: 1.6rem;
    font-weight: bold;

    strong {
      @extend %bold;
    }
  }

  &__name,
  &__content-title {
    @extend %bold;
    font-size: 1.6rem;

    &__name {
      max-width: 450px;
      @include ellipsis();
    }
  }

  &__content-title {
    max-width: 90%;
  }

  &__content__excercise-info {
    .content-common-header__content-title {
      max-width: unset;
    }
  }

  &__name {
    color: $color-dark;
    flex-grow: 1;
    width: 100%;
  }

  &__content-title__index {
    margin-right: 0.5rem;
    text-transform: capitalize;

    &--hidden-index {
      &::after {
        content: initial;
        margin-left: initial;
      }
    }
  }

  &__content-title--activity {
    &__content-title__index {
      &::after {
        content: initial;
        margin-left: initial;
      }
    }
  }
}
