.lesson-search-result {
  &__content {
    align-items: center;
  }

  &__lesson-name-and-focus {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__lesson-name {
    @extend .MD_14px_500;

    color: $color-dark;
  }

  &__lesson-focus {
    @extend .XS_12px_400;

    color: $color-quaternary-darker;
  }

  &__content-image {
    margin-right: $spacingMedium;
    border-radius: 50%;
    width: 56px;
    height: 56px;

    &--empty-container {
      background: $color-quaternary-light;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
