@import '@scss/core-busuu/core-partials/_colors';

.live-lesson-preview {
  padding-top: 80px;

  &--mobile,
  &--tablet {
    padding-top: 50px;
  }

  &--desktop {
    .live-lesson-preview__image {
      position: absolute;
      left: 600px;
    }

    .live-lesson-preview__header {
      .live-lesson-preview__block__text-main {
        max-width: 350px;
      }
    }
  }

  &__label {
    margin-bottom: 8px;
    padding: 4px 12px;
    background: $color-quaternary-light-3;
    border-radius: 4px;
    width: fit-content;
    font-weight: 800;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $color-dark;
  }

  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 24px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    color: $color-dark;

    &__text-main {
      margin-bottom: 16px;
      font-weight: 800;
      font-size: 20px;
      line-height: 130%;
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      color: $color-quaternary-darker;
    }

    &__link-container {
      display: flex;
      font-weight: 400;
      font-size: 16px;
      text-decoration-line: underline;
      color: $color-brand-base;
    }

    img {
      margin-right: 10px;
    }
  }

  &__description {
    margin-bottom: 32px;

    &__details {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        color: $color-dark;
      }
    }
  }

  &__study {
    font-weight: 400;
    font-size: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    background: $color-light;
    padding: 16px;
    font-weight: 400;
    font-size: 16px;
    color: $color-dark;

    &__button {
      border-radius: 32px !important;
      margin: 0 !important;
      margin-top: 16px !important;
    }

    span {
      margin-bottom: 8px;
    }
  }

  &--mobile {
    .live-lesson-preview__footer {
      position: absolute;
      width: 362px;
      position: absolute;
      bottom: 0px;
      margin-left: -17px;
    }
  }

  &--tablet {
    .live-lesson-preview__footer {
      position: absolute;
      bottom: 0px;
      width: 100%;
      margin-left: -131px;
      width: 763px;
      padding-left: 84px;
      padding-right: 80px;
    }
  }

  &--desktop {
    .live-lesson-preview__footer {
      position: absolute;
      left: 750px;
      top: 116px;
      border-radius: 8px;
    }
  }
}
