@import '@scss/core-busuu/core-partials/_colors';

.dialogue-translation {
  width: 100%;
  text-align: center;
  color: $color-brand-base;
  font-size: 16px;
  margin-bottom: 24px;
}

.dialogue-script-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.dialogue-script {
  display: flex;
  margin-bottom: 16px;

  &__photo {
    margin-top: 10px;
    width: 45px;
    height: 45px;
    border-radius: 130px;
  }

  &--left {
    .dialogue-script__photo {
      margin-right: 18px;
    }

    .dialogue-script__content {
      &:before {
        border-width: 25px 20px 25px 0;
        border-right-color: $color-light;
        left: -10px;
      }
    }
  }

  &--right {
    .dialogue-script__photo {
      margin-left: 18px;
    }

    .dialogue-script__content__name {
      display: flex;
      justify-content: flex-end;
    }

    .dialogue-script__content {
      &:before {
        border-width: 25px 0 25px 20px;
        border-left-color: $color-light;
        right: -10px;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    background: $color-light;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &:before {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-style: solid;
      border-color: transparent;
      top: 8px;
    }

    &__name {
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  &__empty-place {
    height: 48px;
    width: 135px;
    fill: transparent;
    stroke: $color-quaternary-dark;
    stroke-width: 4px;
    stroke-dasharray: 8, 8;
  }
}

.dialogue-script-text {
  display: flex;
  flex-wrap: wrap;

  &__word {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__missed-word {
    min-width: 135px;
    height: 50px;
    margin-right: 8px;
    margin-bottom: 4px;
    background-color: $color-light;
    border-radius: 8px;
    font-size: 16px;
    justify-content: center;

    &--answered {
      min-width: unset;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      color: $color-light;
      border: none;
      font-weight: bold;

      .dialogue-script-text__word {
        margin: 0;
      }
    }

    &--correct {
      background-color: $color-primary-base;
    }

    &--incorrect {
      background-color: $color-secondary-base;
    }
  }
}
