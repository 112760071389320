@mixin emptyStateWaiting($parent) {
  @extend %medium;
  align-items: center;
  background: $color-light;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0.2rem dashed $color-quaternary-base;
  border-radius: 0.5rem;
  color: $color-dark;
  display: flex;
  fill: $color-dark;
  flex-direction: column;
  font-size: 1.4rem;
  height: 5rem;
  justify-content: center;
  position: relative;
  transition: #{$animationTime};
  width: 38.2rem;

  &__trash {
    height: 3.2rem;
    padding: 0;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    width: 3.2rem;
  }

  &__browse {
    color: $color-brand-base;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  svg {
    width: 2rem;
  }
}
