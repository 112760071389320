@include uploader('file-upload');

.file-upload {
  @include emptyStateWaiting('file-upload');
  background-size: cover;
  background-position-y: 50%;
  background-position-x: 50%;
  text-align: center;
  transition: none;

  &:hover {
    cursor: pointer;
  }

  &__instructions__description {
    color: $color-dark;
  }

  &--no-text {
    @include noSelect();

    &:hover {
      color: $color-brand-base;
      fill: $color-brand-base;
    }
  }

  &--hoverable {
    &:hover {
      cursor: pointer;
    }
  }

  &--fullscreen {
    height: 27rem;
    width: 100%;
  }

  &--large {
    height: 12rem;
    width: 20rem;
  }

  &--small {
    height: 8rem;
    width: 11rem;
  }

  &--drag-drop-large {
    align-items: center;
    display: flex;
    height: 30rem;
    justify-content: center;
    width: 51.4rem;
  }

  &--drag-drop-small {
    align-items: center;
    display: flex;
    height: 18rem;
    justify-content: center;
    width: 30rem;
  }

  &--drag-and-drop {
    background: $color-quaternary-light;
    background-size: cover;
    border: 0.2rem dashed $color-quaternary-base;
    border-radius: 0.5rem;
  }

  &__dimensions {
    @extend %normal;
    color: $color-quaternary-darker;
    font-size: 1.2rem;
  }

  &__instructions {
    color: $color-quaternary-darker;
    font-size: 1.4rem;
    text-align: center;
  }

  &__browse {
    margin: 0;
  }

  &__file {
    display: inline;
    font-size: 0.8rem !important;
    position: fixed;
    transform: scale(0);
  }

  &__uploaded-image {
    width: unset !important;
    border-radius: 4px;
    max-width: 700px;
    max-height: 270px;

    &--for-lesson {
      width: 60px !important;
      height: 60px;
      border-radius: 50%;
    }
  }

  &--with-error {
    border-color: $color-secondary-base;

    &--failed {
      border-style: solid;
    }
  }

  &--for-lesson {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    fill: $color-brand-base;
    border-radius: 50%;
    border: none;
    background: $color-quaternary-light;
  }

  &__remove-image {
    position: absolute;
    top: 0px;
    right: -5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    background: $color-light;
  }

  &__uploaded-pdf {
    display: flex;
    align-items: center;

    &__image-container {
      position: relative;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-quaternary-light-2;
      border: 1px solid $color-quaternary-light;
      border-radius: 8px;

      .file-upload__remove-image {
        top: -9px;
        right: -12px;
      }
    }

    &__label {
      margin-left: 24px;
      font-weight: 700;
      font-size: 16px;
      color: $color-dark;
      max-width: 800px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &--upload-in-progress {
    border: 1px solid $color-primary-lighter;
  }

  &__progress-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    progress {
      max-width: 50%;
      width: 100%;
      height: 8px;
      border: none;

      &::-webkit-progress-bar {
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      &::-webkit-progress-value {
        background-color: $color-primary-green-4;
        border-radius: 8px;
        transition: all 0.3s;
      }
    }
  }

  &--with-rounded-progress {
    border-color: $color-quaternary-base;

    .file-upload__progress-container {
      width: 60px;
      height: 60px;
      font-weight: 700;
      font-size: 16px;
      color: $color-quaternary-darker-1;

      &__image {
        position: absolute;
        width: 60px;
        height: 60px;

        &__layout {
          fill: none;
          stroke-width: 2px;
          stroke: $color-quaternary-base;
        }
        &__progress {
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
          stroke: $color-primary-green-4;
          stroke-dasharray: 360;
          transition: all 0.3s;
        }
      }
    }
  }

  &--without-border {
    border: none;
  }

  &--dragging {
    background: $color-secondary-4;
    border: 2px dashed $color-brand-base;
    border-radius: 16px;
  }
}

.file-uploaded {
  height: fit-content;
  width: fit-content;
  padding: 0;

  &:hover {
    cursor: default;
  }
}
