@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-RgIt.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-Md.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-xBdIt.woff2) format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-Rg.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-xBd.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-BdIt.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Nista Grot';
  src: url(~@static/fonts/BwNistaGrot-Bd.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

%normal {
  font-family: 'Bw Nista Grot', sans-serif;;
  font-weight: 400;
}

%medium {
  font-family: 'Bw Nista Grot', sans-serif;;
  font-weight: 500;
}

%bold {
  font-family: 'Bw Nista Grot', sans-serif;;
  font-weight: 700;
}
