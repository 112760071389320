@import '@scss/core-busuu/core-partials/_colors';

.spelling {
  &-text {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &__char {
      font-size: 28px;
      margin-right: 8px;
      margin-top: 8px;
    }

    &__missed-char {
      margin-right: 8px;
      border-radius: 8px;
      background-color: hsla(0, 0%, 100%, 0.5);

      &--answered {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-light;
        border: none;

        .spelling-text__char {
          margin: 0;
        }
      }

      &--correct {
        background-color: $color-primary-base;
      }

      &--incorrect {
        background-color: $color-secondary-base;
      }
    }

    &__empty-area {
      width: 52px;
      height: 52px;
      fill: transparent;
      stroke: $color-quaternary-dark;
      stroke-width: 4px;
      stroke-dasharray: 8, 8;
    }
  }

  &-chars {
    display: flex;
    flex-wrap: wrap;
  }

  &-char {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50px;
    height: 50px;
    font-size: 28px;
    background: $color-light;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 16px 16px 0;
    position: relative;
    font-weight: 500;

    &--answered {
      border: 1px solid $color-quaternary-base;
      color: $color-light;
    }
  }
}
