@import '@scss/core-busuu/core-partials/_colors';

.speech-recognition-phrase {
  font-size: 28px;
  margin-bottom: 40px;
}

.speech-recognition-controller {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__hint {
    font-size: 16px;
    color: $color-quaternary-darker;
    margin-bottom: 16px;
  }

  &__button {
    margin-bottom: 28px;
    width: 88px;
  }

  &__skip {
    font-size: 16px;
    color: $color-brand-base;
  }
}
