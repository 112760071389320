.exercise-conversation {
  @include exercise-common-styles();
  $blockName: &;

  &__images {
    @include resetList();

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .file-upload {
      width: 100%;
      height: 18rem;
    }
  }

  &__image {
    margin-right: 2.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__min-number-of-words {
    font-size: 1.6rem;
    height: 4rem;
    width: auto;

    &--with-errors {
      border-color: $color-secondary-base;
    }
  }

  .rdw-editor-main {
    font-size: 2.4rem !important;
    height: 9rem !important;
  }

  .public-DraftEditorPlaceholder-root {
    padding-top: 0.5rem;
  }

  .editor__wrapper .public-DraftEditorPlaceholder-root {
    padding-top: 0;
  }

  .DraftEditor-root * {
    font-size: 2.4rem;
  }
}
