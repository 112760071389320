/* Busuu colors palette
----------------------------------------- */

// Product colors
$color-brand-light: #5398f8;
$color-brand-base: #116eee;
$color-brand-dark: #0e5bc7;
$color-brand-premium: #5438dc;

$color-primary-lighter: #11ee92;
$color-primary-light: #17be14;
$color-primary-base: #008a22;
$color-primary-green: #0ca766;
$color-primary-green-1: #e8fef5;
$color-primary-green-2: #0fd683;
$color-primary-green-3: #e7fdf4;
$color-primary-green-4: #0ebe75;

$color-secondary-light: #e76565;
$color-secondary-base: #e2222e;
$color-secondary-warning: #e84e58;
$color-secondary-1: #fde9eb;
$color-secondary-2: #fff4e6;
$color-secondary-3: #fdf4f4;
$color-secondary-4: #f3f8fe;

$color-tertiary-light: #f7a44a;
$color-tertiary-base: #f88800;

$color-warning: #fffaec;

// Premium plus
$color-premium-plus-light: #3b68a5;
$color-premium-plus-base: #0a428f;

// Premium
$color-premium-base: #9128f1;

// Grey variations
$color-quaternary-light: #f2f7fd;
$color-quaternary-light-1: #ecedee;
$color-quaternary-light-2: #f6fafd;
$color-quaternary-light-3: #e8f1fe;
$color-quaternary-light-4: #cac8c8;
$color-quaternary-light-5: #f3f7fe;
$color-quaternary-base: #d6dee6;
$color-quaternary-dark: #a7b0b7;
$color-quaternary-darker: #666e7e;
$color-quaternary-darker-1: #717578;

// B & W
$color-light: #ffffff;
$color-dark: #252b2f;

// Social channels
$facebook-brand: #3b5998;
$twitter-brand: #00aced;
$whatsapp-brand: #25d366;

// RANDOM COLORS
$random-color-1: #fffcf5;
$random-color-2: #000000;
$random-color-3: #88b7f7;
$random-color-4: #c3dafa;
$random-color-8: #fdfeff;
$random-color-9: #1e2021;
