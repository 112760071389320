.string-search-result,
.resource-bundle-search-result,
.exercise-search-result,
.activity-search-result,
.chapter-search-result,
.lesson-search-result {
  width: 100%;
  padding-bottom: 8px;

  &__info {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__id {
    @extend .XS_12px_400;
    @include discoverableInputOnRollover();

    color: $color-quaternary-darker;

    &:hover {
      cursor: pointer;
    }

    &::before {
      @extend %bold;

      content: 'ID:';
      margin-right: $spacingTiny;
    }
  }

  &__content {
    @extend .MD_14px_500;

    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }

  .activity-copy-button {
    color: $color-brand-base;
    font-size: 16px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 115px;

    &:hover {
      cursor: pointer;
    }
  }

  .context-menu {
    position: absolute;
    right: 0;

    .dropdown-item {
      padding: 5px !important;
    }
  }
}

.search-legacy-block {
  display: flex;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    &__title {
      font-size: 16px;
      font-weight: bold;
    }

    &__description {
      font-size: 12px;
    }
  }
}
