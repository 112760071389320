// these styles are meant *ONLY* to be extended whenever we have a form somewhere in logos
$inputTextHeight: 4.4rem;

.input-text-wrapper {
  @extend %normal;

  border: 0;
  border-radius: 0.8rem;
  color: $color-dark;
  font-size: 1.8rem;
  line-height: 110%;
  position: relative;
  width: 100%;

  &--two-boxes {
    .bidimensional-editor--one-element:focus-within,
    .bidimensional-editor__box-table-wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-text:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .input-text:nth-child(2) {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .input-text {
    border: 0.1rem solid $color-quaternary-base;
  }
}

.input-text {
  border: 0.1rem solid $color-quaternary-base;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  height: $inputTextHeight;
  line-height: 130%;
  padding: 0.9rem 1.4rem;
  width: 100%;

  &--bold {
    @extend %bold;
  }

  &::placeholder {
    @extend %placeholder;
  }

  &:disabled {
    background: $color-quaternary-light;
  }
}

.label-field {
  color: $color-quaternary-darker;
  display: inline-block;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-appearance: none;
  -o-appearance: none;
  border: 0.1rem solid $color-quaternary-base;
  box-shadow: 0 0 0.1rem $color-dark inset;
  cursor: pointer;
  height: 1.6rem;
  margin-right: 0.8rem;
  width: 1.6rem;

  &:checked {
    background: url(~@static/svg/selected-checkbox.svg) no-repeat;
    background-size: cover;
    border: 0;
    opacity: 1;
  }
}

input[type='radio'] {
  border-radius: 50%;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -o-appearance: none;
  border-radius: 0.4rem;
}
