.multi-option-chooser {
  @include resetList();
  @include emptyStateWaiting('multi-option-chooser');
  @include readyForInteractionDashed();
  $minHeight: 11.7rem;
  $borderRadius: 1.6rem;
  $blockName: &;

  align-items: center;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: $minHeight;
  padding: 0;
  width: 100%;

  &--flags {
    .flag__country-flag {
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  &__option {
    @include togglerLookAndFeel();
    @include genericTransition('background');
    align-items: center;
    display: flex;
    height: calc(100% - 1.6rem);
    justify-content: center;
    min-height: calc(#{$minHeight} - #{$spacingMedium});
    padding: 0 0.6rem;
    width: 50%;

    &:first-of-type {
      border-right: 0.1rem solid $color-quaternary-base;
    }

    &:hover {
      .multi-option-chooser__option-inner {
        background: $color-quaternary-light;
      }
    }
  }

  &__option-inner {
    @include genericTransition('background');
    @include noSelect();

    align-items: center;
    border-radius: calc($borderRadius / 2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
  }

  &__option-image {
    margin-bottom: $spacingBasic;
  }

  &__flags-wrapper {
    #{$blockName}__flags {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: $spacingBasic;
      padding: 0;
    }

    #{$blockName}__arrow {
      img {
        width: 1.2rem;
      }
    }

    img {
      margin-right: $spacingBasic;
    }
  }

  &--error {
    border-color: $color-secondary-base;
  }
}
