@import '@scss/core-busuu/core-partials/_colors';

.standalone-menu {
  position: relative;
  width: 100%;
  z-index: map-get($zIndices, 'standalone-menu');

  &--preenabled {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--preenabled,
  &:hover {
    .standalone-menu__inner,
    .content-creator-widget__buttons,
    .content-creator__search {
      opacity: 1;
    }
  }

  &__inner {
    align-items: center;
    background: $color-light;
    border: 0.1rem solid $color-quaternary-base;
    border-radius: 4.8rem;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    list-style: none;
    opacity: 0;
    padding: 0;
    position: relative;
    top: 0.3rem;
    transition: #{$animationTime};
    width: min-content;
    z-index: map-get($zIndices, 'standalone-menu__inner');
  }

  &__buttons__standalone-menu-buttons__wrapper {
    padding: 0 1rem;

    &:hover {
      background-color: $color-light !important;
    }
  }

  &__dropdown {
    border: 0;
    height: initial;
    max-width: initial;
    min-width: initial;
    padding: 0;
    width: 4rem;

    .btn {
      background: $color-light !important;
      border: 0;
      height: 1.5rem !important;
      left: 0;
      min-height: auto;
      min-width: auto;
      position: relative;
      top: 1.2rem;
      width: 1rem;
    }

    &.show {
      button {
        background: none;
        color: $random-color-2;
      }
    }

    button {
      border: 0;
      max-width: initial;
      min-width: initial;
      width: 2rem;
    }
  }

  &__button {
    align-items: center;
    background: transparent;
    border: none;
    border-right: 0.1rem solid $color-quaternary-base;
    cursor: pointer;
    display: flex;
    justify-content: center;
    list-style: none;
    overflow: hidden;
    padding: 0;
    padding: 1rem;

    &:hover {
      background-color: rgba($color-quaternary-base, 0.5);
    }

    &:first-child {
      padding-left: 1.5rem;
      border-bottom-left-radius: 5rem;
      border-top-left-radius: 5rem;

      &:hover {
        background-color: rgba($color-quaternary-base, 0.5);
      }
    }

    &--dialogue-exercise {
      border: none;
      border-radius: 5rem;
    }
  }

  &__icon {
    width: 2rem;
  }

  &__additional-buttons {
    padding: 0 !important;
    position: relative;

    .btn {
      box-shadow: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__more {
    position: absolute;
    top: 0.7rem;
    z-index: map-get($zIndices, 'standalone-menu__more');
  }

  &__buttons__additional-button-text {
    margin-left: 1rem;
  }
}

@for $i from 0 through 200 {
  .standalone-menu--#{$i} {
    @if $i == 0 {
      z-index: map-get($zIndices, 'standalone-menu-with-more-button') + 5;
    } @else {
      @if $i == 1 {
        z-index: map-get($zIndices, 'standalone-menu-with-more-button') + 1;
      } @else {
        z-index: map-get($zIndices, 'standalone-menu-with-more-button') + 1 - (2 * ($i - 1));
      }
    }
  }
}
