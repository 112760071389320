@import '@scss/core-busuu/core-partials/_colors';

.incorrect-feedback {
  position: absolute;
  min-height: 80px;
  background: $color-light;
  bottom: 8px;
  width: 100%;
  padding-top: 16px;
  display: flex;
  flex-direction: column;

  &--desktop,
  &--tablet {
    .incorrect-feedback__description {
      width: 700px;
      margin: 0 auto 4px auto;
    }
    .incorrect-feedback__text {
      width: 700px;
      margin: auto;
    }
  }

  &--mobile {
    padding: 16px;
  }

  &__description {
    font-size: 16px;
    color: $color-quaternary-darker;
    font-weight: 800;
    margin-bottom: 4px;
  }

  &__text {
    width: fit-content;
    font-size: 18px;

    &__value--with-underline {
      border-bottom: 1px dashed $color-quaternary-dark;
    }
  }
}
