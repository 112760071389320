@import '@scss/core-busuu/core-partials/_colors';

.number-indicator {
  @extend %bold;

  align-items: center;
  border-radius: 50%;
  color: $color-light;
  display: inline-flex;
  font-size: 1.4rem;
  height: 2.4rem;
  justify-content: center;
  line-height: 1rem;
  width: 2.4rem;
}
