.exercise-slideshow {
  @include exercise-common-styles();

  &__exercise-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 5rem;
  }

  .file-uploaded {
    border: none;
    margin-left: 1.6rem;
  }
}
