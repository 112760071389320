.pagination {
  .page-link {
    @include noSelect;
    @extend .MD_14px_700;

    border: 0;
    color: $color-quaternary-darker;
    margin-right: $spacingBig;

    &:hover {
      background: transparent;
      cursor: pointer;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 2rem;
      padding-left: 0;
    }

    &:last-child {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page-link.active {
    background: transparent;
    color: $color-brand-base;

    a {
      cursor: default;
    }

    &:hover {
      background: inherit;
      cursor: initial !important;
    }
  }

  .page-link.disabled {
    opacity: 0.25;

    a {
      cursor: default;
    }
  }
}
