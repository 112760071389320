.id-displayer {
  @extend %interactiveZone;
  border-radius: 0.4rem;
  color: $color-quaternary-darker;
  cursor: pointer;
  display: inline-block;
  display: block;
  font-size: 1.4rem !important;
  padding: $spacingTiny 0;
  width: fit-content;

  &--small {
    font-size: 1.2rem !important;
  }

  &__title {
    @extend %bold;
  }

  &__text {
    @extend %bold;
  }

  &__id {
    @extend %normal;
  }
}
