@import '@scss/core-busuu/core-partials/_colors';

.multiple-choice {
  &-distractors {
    display: flex;
    flex-direction: column;

    &--images {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &--tablet,
    &--mobile {
      flex-wrap: wrap;
    }
  }

  &-button,
  &-image-button__caption {
    position: relative;
    padding: 13px;
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
    background: $color-light;
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    font-weight: bold;

    &--unknown {
      opacity: 0.5;
    }

    &--correct {
      background: $color-primary-base;
      color: $color-light;
    }

    &--incorrect {
      background: $color-secondary-base;
      color: $color-light;
    }
  }

  &-image-button {
    margin: 0 8px 0 8px;
    position: relative;

    &__caption {
      border-radius: 0px 0px 8px 8px;
    }

    &--unknown {
      opacity: 0.5;
    }

    &__image {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      height: 162px;
    }

    &--desktop {
      max-width: 223px;
    }
    &--tablet {
      max-width: 223px;
    }

    &--mobile {
      max-width: 328px;

      .multiple-choice-image-button__image {
        margin: 0px;
        width: 328px;
        height: 154px;
      }
    }

    &__image {
      height: 154px;
    }
  }
}
