.name-container {
  display: flex;
  align-items: center;

  &__image {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
