@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin resetList {
  margin: 0;
  padding: 0;
  text-indent: 0;

  li {
    list-style: none;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// alias

@mixin increaseBrightness() {
  transition: filter #{$animationTime};

  &:hover {
    filter: brightness(1.2);
  }
}

@mixin modalDimensions() {
  min-width: 60rem;
  right: 0;
  width: 33vw;
}

@mixin shineOnRollover($brightness: 1) {
  cursor: pointer;
  filter: brightness(1);

  &:hover {
    filter: brightness($brightness);
    transition: filter 0.5s;
  }
}

@mixin genericTransition($prop: '', $time: 0.5s) {
  @if $prop== '' {
    transition: all $time;

    &:hover {
      transition: all $time;
    }
  } @else {
    transition: #{$prop} $time;

    &:hover {
      transition: #{$prop} $time;
    }
  }
}

@mixin contentCreatorWidgetLine($blockName) {
  #{$blockName}__line {
    height: 0.05rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    transition: 0.25s;
    width: 100%;

    #{$blockName}--preenabled {
      display: none;
    }
  }

  #{$blockName}:hover {
    #{$blockName}__line {
      background: $color-brand-base !important;
      height: 0.3rem !important;
      opacity: 1;
    }
  }
}

@mixin applyFocus() {
  &:focus-within,
  &:active {
    border: 0.1rem solid $color-brand-base !important;
  }
}

@mixin disableApplyFocus() {
  &:focus-within,
  &:active {
    border: 0 !important;
  }
}

@mixin discoverableInputOnRollover() {
  border-radius: 0.4rem;
  transition: background $animationTime, border-radius $animationTime;

  &:focus {
    background: $color-quaternary-light;
    cursor: text;
  }

  &:hover {
    background: $color-quaternary-light;
    border-radius: 0.4rem;
    transition: background $animationTime, border-radius $animationTime;
  }
}

@mixin readyForInteractionDashed() {
  border: 0.2rem dashed $color-quaternary-base;
}

@mixin togglerLookAndFeel() {
  @extend %normal;

  color: $color-brand-base;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
}

@mixin roundedStressedAreaWithDistractors($blockName) {
  @extend .exercise-multiple-choice__rounded-stressed-area;

  .#{$blockName}__answer,
  .#{$blockName}__distractor {
    @extend .input-text;

    display: block;
  }

  .translation-tip:nth-of-type(1) {
    margin-bottom: 1.6rem;
  }

  .#{$blockName}__wrapper:last-child {
    margin-bottom: 0;
  }

  .translation-tip:nth-of-type(1) {
    z-index: map-get($zIndices, 'roundedStressedAreaWithDistractors__translation-tip');
  }
}

@mixin uploader($className) {
  .#{$className} {
    @include emptyStateWaiting($className);
    cursor: default;

    &--fullscreen {
      width: 100%;
    }

    &--with-file {
      align-items: center;
      border: 0.1rem solid $color-quaternary-base;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }

    &--processing-file {
      justify-content: center;
    }

    &--without-file {
      @include readyForInteractionDashed();
    }

    &__instructions {
      color: $color-quaternary-darker;
      font-size: 1.4rem;
      text-align: center;
    }

    &__upload-icon {
      margin-right: 0.5rem;
    }

    &__info-icon {
      margin-right: 1rem;
    }
  }
}

@mixin preloaderAnimation {
  @keyframes preloader {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: preloader 2s infinite;
}

@mixin modalClosable() {
  .modal-header {
    align-items: center;
    border-bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;

    .close {
      color: $color-quaternary-dark;
      font-size: 4rem;
      font-weight: normal;
      margin: 0;
      margin-top: 0;
      opacity: 1;
      padding: 0;
      padding-top: 0;
    }
  }
}

@mixin customStyledScrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-quaternary-dark;
    transition: all 0.5s;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-quaternary-darker;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
