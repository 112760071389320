.ktags-with-audio-creator {
  @extend .ktags-creator;
  @include disableApplyFocus();
  border: 0;

  * {
    transition: border 0s !important;
  }

  &:focus-within,
  &:active {
    border: 0 !important;
    transition: border 0s !important;

    .audio-upload {
      border: 0.1rem solid $color-brand-base !important;
      border-top: 0 !important;
      transition: border 0s !important;
    }
  }

  .ktags-creator {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .audio-upload {
    @include applyFocus();

    border-bottom-left-radius: $genericBorderRadius;
    border-bottom-right-radius: $genericBorderRadius;
    border-style: solid;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0.1rem;
  }

  .DraftEditor-root * {
    font-size: 1.6rem;
  }

  .translation-tip {
    position: absolute;
    right: 0;
    z-index: map-get($zIndices, 'ktags-with-audio-creator__translation-tip');
  }
}
