.exercise-fill-gap {
  @include exercise-common-styles();

  &__distractors {
    @include resetList();
  }

  &__distractor {
    margin-bottom: $spacingBasic;
  }
}
