.exercise-search-result {
  &__icon {
    margin-right: $spacingMedium;
    width: 56px;
    height: 56px;
    background: $color-quaternary-light-5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__exercise-type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
