@import '@scss/core-busuu/core-partials/_colors';

.phrase-builder-field {
  width: 100%;
  min-height: 150px;
  border: 1px solid $color-quaternary-base;
  border-radius: 8px;
  margin-bottom: 40px;
  background-color: $color-light;
  padding: 16px;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.phrase-builder-words {
  display: flex;
  flex-wrap: wrap;

  &__word {
    position: relative;
    font-size: 16px;
    padding: 10px 20px;
    background: $color-light;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 16px 16px 0;

    &--answered {
      border: 2px solid $color-quaternary-base;
      color: $color-light;
      box-shadow: none;
    }

    &--correct {
      background-color: $color-primary-green;
      color: $color-light;
    }

    &--incorrect {
      background-color: $color-secondary-base;
      color: $color-light;
    }
  }
}
