.modal.phoneme-table {
  $blockName: '.phoneme-table';

  $width: 67.2rem;
  $height: 55.9rem;
  align-items: center;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  height: $height;
  justify-content: center;
  left: calc(50vw - #{$width} / 2);
  top: calc(50vh - #{$height} / 2);
  width: $width;

  .modal-content {
    border-radius: 0.8rem;
    height: 100%;
    padding: 4rem;
  }

  .modal-header {
    @include noSelect();

    border-bottom: 0;
    margin: 0;
    margin-bottom: $spacingLarge;
    padding: 0;
  }

  .modal-title {
    font-size: 2.4rem;
    text-align: center;
    width: 100%;
  }

  .modal-dialog {
    height: 100%;
    max-width: initial;

    .modal-body {
      flex-grow: 1;
      margin-bottom: $spacingLarge;
      min-height: initial;
      padding: 0;
    }
  }

  #{$blockName}__save-cancel {
    @extend .translations-panel-modal__save-cancel;

    box-shadow: none;
    padding: 0;
    position: static;
    width: 100%;
  }

  #{$blockName}__cancel {
    @extend .translations-panel-modal__cancel;
  }

  #{$blockName}__phonemes {
    @include resetList();

    border: 0.1rem solid $color-quaternary-base;
    border-radius: $spacingBasic;
    cursor: pointer;
    height: 100%;
    overflow: hidden;

    rect {
      @include genericTransition('fill');
    }
  }
}
