@import '@scss/core-busuu/core-partials/_colors';

.resource-bundle-modal {
  min-width: 948px;

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    padding: 16px;
    border-radius: 16px;
  }

  &__close {
    width: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  &__image_and-video-container {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;

    .file-uploaded {
      width: 100%;

      .file-upload__uploaded-image {
        width: 100% !important;
        height: 300px;
      }
    }
  }

  &__empty-uploader {
    max-width: 430px;
  }

  &__warning {
    padding: 18px;
    margin-bottom: 24px;
    width: 100%;
    background-color: $color-warning;
    border: none;
    height: 48px;
    color: $color-dark;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }

  .file-upload__browse:hover {
    cursor: default;
    text-decoration: none;
  }
}
