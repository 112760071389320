@import '@scss/core-busuu/core-partials/_colors';

.table-item {
  display: grid;
  grid-template-columns: 15% 45% 15% 25%;
  font-size: 14px;
  color: $color-quaternary-darker;
  padding: 10px 0;
  border-bottom: 1px solid $color-quaternary-base;
  align-items: center;

  &--dark-text {
    color: $color-dark;
  }

  &--bigger-font {
    font-size: 16px;
  }

  &--smaller-font {
    font-size: 12px;
  }

  &__permissions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      padding: 4px;
      font-weight: 500;
      font-size: 10px;
      background: $color-brand-base;
      border-radius: 4px;
      color: $color-light;
      margin: 0 6px 6px 0;
    }
  }

  &__overview {
    align-self: start;
  }
}
