@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.exercise-preview-for-list__content {
  width: 100%;

  .exercise-preview__header {
    height: 8.5rem;
    padding: 1.6rem 2.4rem 1.6rem 0;
  }

  .exercise-preview__content {
    @include customStyledScrollbar();
    overflow: overlay;
    max-height: calc(90vh - 17.7rem);
    min-height: calc(100% - 18rem);

    &--mobile {
      &--correct,
      &--incorrect {
        min-height: calc(100% - 18rem);
      }
    }
  }
}
