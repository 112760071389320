@import '@scss/core-busuu/core-partials/_colors';

.exercise-preview__content {
  width: 100%;
  min-height: calc(100% - 17.5rem);
  background: $color-quaternary-light;
  margin: auto;
  border-right: 1px solid $color-quaternary-light-1;
  border-left: 1px solid $color-quaternary-light-1;
  padding-top: 17px;
  position: relative;

  .preview__content {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin: auto;
    position: relative;
  }

  // &--mobile {
  //   &--1,
  //   &--2 {
  //     min-height: calc(100% - 220px);
  //   }
  // }

  &--without-footer {
    min-height: calc(100% - 83px);
  }

  .preview__buttons-container {
    position: absolute;
    top: 17px;
    left: 29px;

    img {
      margin-right: 10px;
    }
  }

  .preview__close-icon {
    position: absolute;
    top: 17px;
    right: 29px;
  }

  &--tablet {
    max-width: 763px;

    .preview__buttons-container {
      left: 29px;
    }

    .preview__close-icon {
      right: 31px;
    }

    .preview__content {
      width: 500px;

      .preview__progress-bar {
        width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--mobile {
    max-width: 361px;

    .preview__buttons-container {
      left: 16px;
    }

    .preview__close-icon {
      right: 16px;
    }

    .preview__content {
      width: 328px;

      .preview__progress-bar {
        width: 216px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
