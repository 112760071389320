.ktags-creator {
  @include applyFocus();
  border: 0.1rem solid $color-quaternary-base;
  border-radius: $genericBorderRadius;
  overflow: hidden;
  position: relative;

  .public-DraftEditorPlaceholder-root {
    padding-top: 0.5rem;
  }

  .public-DraftStyleDefault-block {
    margin-top: 0;
    padding-top: 0;
  }

  .DraftEditor-root * {
    font-size: 2.4rem;
  }

  .rdw-editor-main {
    cursor: text;
  }

  .rdw-editor-toolbar {
    @extend %resetElement;

    align-items: center;
    background: $color-quaternary-light;
    border-bottom: 0.1rem solid $color-quaternary-base;
    border-radius: 0;
    border-top-left-radius: $genericBorderRadius;
    border-top-right-radius: $genericBorderRadius;
    display: flex;
    flex-direction: row;
    height: 4rem;
    justify-content: flex-start;
    padding: 0 1.6rem;
  }

  .rdw-editor-toolbar button {
    @extend %button-as-link-dark;

    align-items: center;
    color: $color-dark;
    display: flex;
    font-size: 1.2rem;
    justify-content: flex-start;
    margin-right: 1.6rem;
    padding: 0;
    transition: 0.5s;

    &:hover {
      color: lighten($color-dark, 20);
    }

    img {
      margin-right: 0.6rem;
    }
  }

  &__output {
    @extend %normal;

    background: $color-quaternary-base;
    border-bottom-left-radius: $genericBorderRadius;
    border-bottom-right-radius: $genericBorderRadius;
    bottom: -0.4rem;
    font-size: 1.2rem;
    left: 0;
    max-height: 9.5rem;
    overflow: hidden;
    padding: 1rem;
    padding-top: 0.5rem;
    position: absolute;
    width: 100%;
    z-index: map-get($zIndices, 'ktags-creator');
  }

  &__gap-image {
    background: url(~@components/Exercises/Common/KTagsCreator/img/group-creator.svg) no-repeat;
    height: 1rem;
    margin-right: 0.5rem;
    width: 1.4rem;
  }

  &--with-error {
    border-color: $color-secondary-base !important;
  }

  &--with-warning {
    border-color: $color-tertiary-base !important;
  }
}
