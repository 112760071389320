@import '@scss/core-busuu/core-partials/_colors';

.error-displayer-from-other-places {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: $random-color-1;
  border-radius: 8px;
  font-size: 14px;

  &__image {
    margin-right: 6px;
  }

  &__link {
    font-size: 16px;
    margin-left: 4px;
    color: $color-brand-base;

    &:hover {
      cursor: pointer;
    }
  }
}
