// course edition
$logosCourseNavigationWidth: 29rem;
$logosCourseNavigationCollapsedWidth: 9.6rem;
$exerciseHeaderHeight: 7.2rem;

$animationTime: 0.5s;
$sidePanelPaddingLeft: 5.5rem;
$sidePanelFooterHeight: 8rem;

// dialogue
$dialogueCharacterSizeRegularSize: 8.8rem;
$dialogueCharacterSizeMediumSize: 7.2rem;
$dialogueCharacterSizeSmallSize: calc($dialogueCharacterSizeRegularSize / 2);

// generic border radius for the whole project
$genericBorderRadius: 0.8rem;

// z-indices
$zIndices: (
  'bidimensional-editor__add-column-tip': 1,
  'bidimensional-editor__add-column': 10,
  'bidimensional-editor__add-row-tip': 1,
  'bidimensional-editor__add-row': 10,
  'dialogue-character__border': 1,
  'dialogue-character-with-number__number-indicator': 1,
  'content-creator-widget__buttons': 1,
  'standalone-menu__inner': 1,
  'standalone-menu__more': 10,
  'fill-gap-tool__gaps': 10,
  'gapping-tool__characters': 10,
  'translation-tip': 4000,
  'roundedStressedAreaWithDistractors__translation-tip': 11,
  'bidimensional-editor__column-selector-remove-icon': 100,
  'bidimensional-editor__row-selector-remove-icon': 100,
  'ktags-with-audio-creator__translation-tip': 100,
  'translations-panel-modal__save-cancel': 100,
  'ktags-creator': 1000,
  'exercise-dialogue__replace-character-background': 1000,
  'course-composer__el': 3097,
  'course-edition__exercise-header': 3100,
  'modal-backdrop': 5500,
  'snack-bar': 7000,
  'modal': 8000,
  'sidepanel': 8000,
  'exercise-dialogue__replace-character': 9000,
  'dropdown-menu.show': 10000,
  'standalone-menu': 1990,
  'standalone-menu-with-more-button': 3095,
  'popover-search-results': 12000,
  'preloader-fullscreen': 13000,
  'tooltip': 14000,
  'bs-popover-bottom': 15000,
);

// spacing gaps
$spacingMinimal: 0.1rem; // based on 0.1rem means 1px
$spacingTiny: $spacingMinimal * 2;
$spacingSmall: $spacingMinimal * 4;
$spacingBasic: $spacingMinimal * 8;
$spacingMedium: $spacingMinimal * 16;
$spacingBig: $spacingMinimal * 24;
$spacingLarge: $spacingMinimal * 32;
$spacingHuge: $spacingMinimal * 40;
