.listContainer {
  padding: 0;
  td {
    padding: 4px;
  }
}

.listItem {
  list-style: none;
}
