/* Fonts
--------------------------- */
$default-typo: 'Helvetica Neue, Helvetica, Arial, sans-serif';
$default-typo-serif: 'Georgia, Times, Times New Roman, serif';

%font-face-lt {
  font-family: 'Bw Nista Grot', unquote($default-typo);
}
%font-face-pl,
%font-face-tr,
%font-face-vi {
  font-family: unquote($default-typo);
}
%font-face-ar {
  font-family: 'Geeza Pro', unquote($default-typo);
}
%font-face-ja {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'Osaka', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック',
    'MS PGothic', unquote($default-typo);
}
%font-face-ko {
  font-family: 'Apple SD Gothic Neo', 'NanumBarunGothic', '맑은 고딕', 'Malgun Gothic', '굴림', 'Gulim', '돋움', 'Dotum',
    unquote($default-typo);
}
%font-face-ru {
  font-family: 'Charcoal', 'Geneva', unquote($default-typo);
}
%font-face-zh {
  font-family: 'PingFang SC', 'PingFang TC', '华文细黑', 'STXihei', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑',
    'Microsoft Yahei', '宋体', 'SimSun', unquote($default-typo);
}
%font-face-serif {
  font-family: unquote($default-typo-serif);
}

.font-face-lt,
.font-face-lt body {
  @extend %font-face-lt;
  font-weight: 400;
}
.font-face-ar,
.font-face-ar body {
  @extend %font-face-ar;
  font-weight: 400;
}
.font-face-ja,
.font-face-ja body {
  @extend %font-face-ja;
  font-weight: 400;
}
.font-face-ko,
.font-face-ko body {
  @extend %font-face-ko;
  font-weight: 400;
}
.font-face-pl,
.font-face-pl body {
  @extend %font-face-pl;
  font-weight: 400;
}
.font-face-ru,
.font-face-ru body {
  @extend %font-face-ru;
  font-weight: 400;
}
.font-face-tr,
.font-face-tr body {
  @extend %font-face-tr;
  font-weight: 400;
}
.font-face-vi,
.font-face-vi body {
  @extend %font-face-vi;
  font-weight: 400;
}
.font-face-zh,
.font-face-zh body {
  @extend %font-face-zh;
  font-weight: 400;
}
.font-face-serif,
.font-face-serif body {
  @extend %font-face-serif;
  font-weight: 400;
}
