@import '@scss/core-busuu/core-partials/_colors';

.matchup-cards {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
  text-align: center;
  width: 100%;
  margin: 0;

  &--tablet {
    width: 700px;
    margin-left: -100px;
  }

  &--mobile {
    width: 100%;
    margin: unset;
    display: unset;
  }

  &--for-fixed {
    margin-bottom: 60px;
  }

  &--for-two-pairs {
    grid-template-columns: 49% 49%;
  }
}

.matchup-fixed-card {
  display: flex;
  flex-direction: column;
  font-weight: bold;

  &__text {
    position: relative;
    background-color: $color-light;
    border-radius: 8px 8px 0 0;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-size: 20px 20px;
      height: 20px;
      width: 100%;
      bottom: -10px;
      background-image: linear-gradient(155deg, #fff 50%, transparent 0),
        linear-gradient(-155deg, #fff 50%, transparent 0);
    }
  }

  &__area {
    height: 48px;
    fill: transparent;
    stroke: $color-quaternary-dark;
    stroke-width: 4px;
    stroke-dasharray: 8, 8;
    margin-top: 9px;
  }
}

.matchup-variant-card {
  display: flex;
  min-height: 48px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $color-light;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 16px;
  padding: 10px 20px;
  margin-top: 6px;
  font-weight: bold;

  &__area {
    padding: 12px 16px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-size: 20px 20px;
    height: 20px;
    width: 100%;
    background-image: linear-gradient(155deg, #fff 50%, transparent 0),
      linear-gradient(-155deg, #fff 50%, transparent 0);
    transform: rotate(180deg);
    top: -10px;
  }

  &--correct {
    background-color: $color-primary-base;
    color: $color-light;
    margin-top: 12px;

    &:before,
    &:after {
      transform: rotate(0deg);
      top: -5px;
    }
    &:before {
      background-image: none;
    }

    &:after {
      background-image: linear-gradient(25deg, $color-primary-base 50%, transparent 0),
        linear-gradient(-25deg, $color-primary-base 50%, transparent 0);
      background-position: -10px 0;
      top: -10px;
    }
  }

  &--incorrect {
    background-color: $color-secondary-base;
    color: $color-light;
    margin-top: 12px;

    &:before,
    &:after {
      transform: rotate(0deg);
      top: -5px;
    }
    &:before {
      background-image: none;
    }

    &:after {
      background-image: linear-gradient(25deg, $color-secondary-base 50%, transparent 0),
        linear-gradient(-25deg, $color-secondary-base 50%, transparent 0);
      background-position: -10px 0;
      top: -10px;
    }
  }

  &--empty {
    border: 2px solid $color-quaternary-base;
    border-radius: 8px;
    background-color: hsla(0, 0%, 100%, 0.5);
    height: 50px;
    box-shadow: none;
    margin-bottom: 16px;

    &:before,
    &:after {
      content: none;
    }
  }
}
