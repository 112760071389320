.exercise-common-styles {
  &__header-buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__instructions-wrapper {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__box-title {
    @extend .label-field;
  }

  &__checkbox-container {
    align-items: center;
    color: $color-quaternary-darker;
    display: flex;
    font-size: 1.4rem;
    justify-content: flex-start;
  }

  &__options {
    @include resetList();

    align-items: center;
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  &__option {
    @include resetList();

    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-right: 3.2rem;
  }

  &__ready-to-publish {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    justify-content: center;
  }

  &__option-radio {
    margin-right: 0.5rem;
  }

  &__add-to-vocabulary-checkbox {
    margin-right: 0.8rem;
  }

  &__wrapper {
    margin-bottom: 3.2rem;
  }

  &__wrapper-double {
    margin-bottom: 3.2rem;
    margin-top: 1.2rem;
  }
}

@mixin exercise-common-styles() {
  .show-to-users-in-course-language,
  .add-to-vocabulary-review {
    align-items: center;
    display: inline-flex;
    margin-top: 0.8rem;
  }

  &__header-buttons {
    @extend .exercise-common-styles__header-buttons;
  }

  &__instructions-wrapper {
    @extend .exercise-common-styles__instructions-wrapper;
  }

  &__container {
    @extend .exercise-common-styles__container;
  }

  &__box-title {
    @extend .exercise-common-styles__box-title;
  }

  &__checkbox-container {
    @extend .exercise-common-styles__checkbox-container;
  }

  &__options {
    @extend .exercise-common-styles__options;
  }

  &__option {
    @extend .exercise-common-styles__option;
  }

  &__ready-to-publish {
    @extend .exercise-common-styles__ready-to-publish;
  }

  &__option-radio {
    @extend .exercise-common-styles__option-radio;
  }

  &__wrapper {
    @extend .exercise-common-styles__wrapper;
  }

  &__wrapper-double {
    @extend .exercise-common-styles__wrapper-double;
  }

  &__rounded-stressed-area {
    background: $color-quaternary-light;
    border-radius: 1.6rem;
    margin-bottom: 0.2rem;
    padding: 1.6rem;
  }
}
