@import '@scss/core-busuu/core-partials/_colors';
@import '@scss/utils/_mixins';

.string-reuse-modal {
  .modal-content {
    border-radius: 16px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    max-height: 678px;
    align-items: center;
    padding: 40px;
  }

  &__title {
    color: $color-dark;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 8px;
    text-align: center;
  }

  &__description {
    color: $color-quaternary-darker;
    font-size: 16px;
    margin-bottom: 32px;
    text-align: center;
  }

  &__other-locations {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    margin-bottom: 12px;
    @include customStyledScrollbar();

    &__location {
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $color-quaternary-base;

      &:last-child {
        border-bottom: none;
      }

      &__header {
        margin-bottom: 4px;
        font-size: 14px;
        color: $color-quaternary-darker;

        &__language {
          margin-right: 25px;
        }
      }

      &__name {
        font-weight: 800;
        font-size: 16px;
        color: $color-dark;
        overflow-wrap: break-word;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__button {
      border-radius: 32px !important;
      margin-bottom: 8px !important;
    }

    &__edit {
      border: 2px solid $color-brand-base !important;

      &--disabled {
        opacity: 0.5;

        &:hover {
          cursor: default !important;
        }
      }
    }
  }

  &__navigate {
    margin-bottom: 5px;
  }
}
