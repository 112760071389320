@import '@scss/core-busuu/core-partials/_colors';

.comprehension-title {
  font-size: 30px;
  margin-bottom: 8px;
}

.comprehension-video {
  position: relative;

  & > div:first-child {
    height: fit-content !important;
  }
}

.comprehension-provider {
  font-size: 14px;
  color: $color-quaternary-darker;
  margin-bottom: 16px;
}

.comprehension-text {
  font-size: 16px;
}
