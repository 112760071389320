@import '@scss/core-busuu/core-partials/_colors';

.exercise-preview__header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid $color-quaternary-light-1;

  &__devices-types-container {
    display: flex;
    align-items: center;
  }

  &__device-container {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: transparent;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__device-container--active {
    background: $color-brand-base;
  }

  &__done-button {
    border-radius: 32px !important;
  }
}
