@import '@scss/core-busuu/core-partials/_colors';

.keyboard-shortcut {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -10px;
  right: -10px;
  background: $color-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.7;
  font-weight: 800;
  border: 1px solid $color-quaternary-base;
  color: $color-quaternary-darker;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(214, 222, 230, 0.6);
  text-align: center;
}
