@import '@scss/core-busuu/core-partials/_colors';

.admin-settings-wrapper {
  padding: 0 40px;
  width: 100%;

  .admin-settings-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-height: 55px;
    margin-bottom: 60px;
    margin-top: 40px;
    width: 100%;

    .dropdown-menu.show {
      transform: translate3d(-220px, 45px, 0px) !important;
    }
  }

  .admin-settings {
    display: flex;
    flex-direction: column;
    color: $color-dark;

    &__title {
      font-weight: 800;
      font-size: 30px;
      letter-spacing: -0.015em;
      margin-bottom: 12px;
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      &__block {
        display: flex;
        width: fit-content;

        &__filter {
          width: 210px;
          margin: 0 10px 0 0;

          > button {
            margin: 0;
            border: 0.1rem solid $color-quaternary-base;
          }
        }
      }

      &__search {
        width: 240px;
      }
    }
  }
}
