/* Typographies
----------------------------- */

$fontsize-xxxl: 2.25rem; //36px
$fontsize-xxl: 1.875rem; //30px
$fontsize-xll: 1.75rem; //28px
$fontsize-xl: 1.5rem; //24px
$fontsize-ll: 1.25rem; //20px
$fontsize-l: 1.125rem; //18px
$fontsize-m: 1rem; //16px
$fontsize-s: 0.875rem; //14px
$fontsize-xs: 0.75rem; //12px
$fontsize-xxs: 0.625rem; //10px

$fontweight-l: 700; // Bold
$fontweight-m: 500; // Normal
$fontweight-s: 400; // Light

/**
 * Mapping names with sizes
 */
$fontSizeMap: (
  'xxxl': $fontsize-xxxl,
  'xxl': $fontsize-xxl,
  'xl': $fontsize-xl,
  'l': $fontsize-l,
  'm': $fontsize-m,
  's': $fontsize-s,
  'xs': $fontsize-xs,
  'xxs': $fontsize-xxs,
);

@function getFontSize($key) {
  @if map-has-key($fontSizeMap, $key) {
    @return map-get($fontSizeMap, $key);
  }
  @return 100%;
}

@function getLineHeight($fontSize, $lineHeight: null) {
  @if ($lineHeight) {
    @return $lineHeight;
  }

  $lineHeight: 1.7;

  @if ($fontSize == 'xxxl' or $fontSize == 'xxl') {
    $lineHeight: 1.5;
  }

  @return $lineHeight;
}

/**
 * Mixin to associate font-size with his related line-height
 */
@mixin text-style($fontSize, $lineHeight: null) {
  font-size: getFontSize($fontSize);
  line-height: getLineHeight($fontSize, $lineHeight);
}
