.exercise-comprehension {
  @include exercise-common-styles();

  .rdw-editor-main {
    height: 17rem !important;
  }

  &__video-wrapper {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;

    .file-upload--fullscreen {
      height: 36rem;
    }

    .file-upload__uploaded-image {
      max-height: 360px;
      height: 100%;
      max-width: unset;
      width: 100% !important;
    }
  }
}
