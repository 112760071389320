@import '@scss/core-busuu/core-partials/_colors';

// when called like creator("exercise-dialogue__creator", "dialogues")
@mixin creator($name, $namePlural) {
  @include contentCreatorWidgetLine($name);

  // you get "exercise-dialogue__creator"
  .#{$name} {
    background: $color-light;
    border: 0.2rem dashed $color-quaternary-base;
    border-radius: 1.6rem;
    box-sizing: border-box;
    display: block;
    opacity: 1;
    padding: 5rem;
    width: 100%;

    // you get "exercise-dialogue__creator--dialogues-dont-exist"
    &--#{$namePlural}-dont-exist {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content-creator-widget__line {
        display: none;
      }
      .standalone-menu {
        flex-direction: column;
      }
    }

    // you get "exercise-dialogue__creator--dialogues-exist"
    &--#{$namePlural}-exist {
      border: 0;
      padding: 0;
    }

    // you get "exercise-dialogue__creator__title"
    &__title {
      margin-bottom: 24px;
      color: $color-dark;
      font-size: 1.8rem;
      font-weight: normal;
      line-height: 150%;
      text-align: center;
    }

    .standalone-menu {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
