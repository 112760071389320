@import '@scss/core-busuu/core-partials/_colors';

.highlighter {
  &-phrases {
    display: flex;
    flex-direction: column;
  }
}

.highlighter {
  &-phrase {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0 16px 0;
    border-bottom: 1px solid $color-quaternary-base;

    &__word {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 10px 20px;
      font-size: 16px;
      background: $color-light;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin: 0 16px 16px 0;
      position: relative;
      font-weight: bold;

      &--correct {
        box-shadow: none;
        background-color: $color-primary-base;
        color: $color-light;
      }

      &--incorrect {
        box-shadow: none;
        background-color: $color-secondary-base;
        color: $color-light;
      }

      &--unknown {
        opacity: 0.5;
      }
    }
  }
}
